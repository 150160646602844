import React, {useEffect} from "react"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './index.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/performance';
// import { getPerformance } from "firebase/performance";


// import {getAnalytics} from "firebase/analytics";
import {BrowserRouter, Routes, Route} from "react-router-dom";

import config from "./config/config";

import * as serviceWorker from './serviceWorker';
import {SnackbarProvider} from "notistack";
import Button from '@mui/material/Button';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {render} from "react-dom";

import './i18n/config';

import BrandingCssVarsProvider from "./components/BrandingCssVarsProvider";
import BrandingProvider from "./components/BrandingProvider";
import Client from "./Client";
import useLocalStorageState from "./utils/useLocalStorageState";
import {BackendRestApiInstance, setLastChatAsCurrent} from "./api/BackendRestApi";
import {createRoot} from "react-dom/client";
import {useAppStore, useSettingsStore} from "./state_store";
import {AuthDialog} from "./components/AuthDialogAndFunctions";
import {DashboardWindow} from "./components/PersonalDashboardWinModalComponent";
import MediaOnboardingDialog from "./components/MediaOnboardingDialog/MediaOnboardingDialog";
import TopUpBalanceDialog from "./components/TopUpBalanceDialog";
import AccountDeletionDialog from "./components/AccountDeletionDialog";

import {PostHogProvider} from 'posthog-js/react'
import reportWebVitals from './reportWebVitals';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  enable_recording_console_log: true,
  enable_heatmaps: true,
  disable_session_recording: false,

  // FIXME: not mentioned in docs directly.
  capture_performance: true
}

// add action to all snackbars
const notistackRef: React.RefObject<any> = React.createRef();
const onClickDismiss = (key: any) => () => {
  notistackRef.current.closeSnackbar(key);
}

//
// const domContainer = document.querySelector("#root")
// const root = createRoot(document.getElementById("root"))
const root = document.getElementById("root");

const firebaseApp = firebase.initializeApp(config.firebase);
// const analytics = getAnalytics(firebaseApp);
// const analytics = firebase.analytics(firebaseApp);
const analytics = firebase.analytics(firebaseApp);
const performance = firebase.performance(firebaseApp);

analytics.logEvent('App loaded');
// firebase.auth().useDeviceLanguage(); // set with function
// firebase.auth().languageCode = 'ru'; // set with string


function fetchUserData() {
  try {
    // Swallow the exceptions, e.g. rate limit
    // FIXME: do same on log-in/out events

    // console.log('fetchLastChatOnPageRefresh ', useSettingsStore.getState().fetchLastChatOnPageRefresh )
    // console.log('have token: ', useAppStore.getState().loginToken )
    const haveLoginToken = Boolean(useAppStore.getState().loginToken)
    // console.log('have token: ', haveLoginToken)
    // console.log('token: ', useAppStore.getState().loginToken)
    // console.log('have process.env.DEV_INIT_LOGIN_TOKEN token: ', process.env.DEV_INIT_LOGIN_TOKEN )
    //
    if (haveLoginToken) {
      BackendRestApiInstance.getChats(
        useSettingsStore.getState().fetchLastChatOnPageRefresh ? setLastChatAsCurrent : null
      );
    }

    // Firebase UI only works on the Client. So we're loading the package in `componentDidMount`
    // So that this works when doing server-side rendering.
    //const firebaseui = require('firebaseui');

    // firebase.auth()

    const url = window.location.href;
    const pathname = window.location.pathname;

    // console.log(window.location.pathname); //yields: "/js" (where snippets run)
    // console.log(window.location.href);     //yields: "https://stacksnippets.net/js"

    if (pathname == '/data_deletion') {
      useAppStore.getState().setAccountDeletionDialogOpen(true)
    }
  } catch (e) {

  }
}

function Base() {
  const [mode, setMode] = useLocalStorageState('theme', 'system');

  useEffect(() => {
    // run startup tasks
    // console.log('Root component mounted')
    fetchUserData();
  }, []);

  return <BrandingCssVarsProvider>
    {/*@ts-ignore*/}
    <BrandingProvider mode={mode}>

      {/*<ThemeProvider theme={appTheme}>*/}
      <CssBaseline/>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        <SnackbarProvider
          ref={notistackRef}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={4000}
          maxSnack={3}
          action={(key) => (
            <Button onClick={onClickDismiss(key)}>Close</Button>
          )}>

          <BrowserRouter>
            <Client mode={mode} setMode={setMode}/>
            <DashboardWindow/>
            <TopUpBalanceDialog/>
            <AccountDeletionDialog/>
            <AuthDialog/>
            <MediaOnboardingDialog/>
          </BrowserRouter>

        </SnackbarProvider>
      </PostHogProvider>
      {/*</ThemeProvider>*/}
    </BrandingProvider>
  </BrandingCssVarsProvider>
}

render(
  <React.StrictMode>
    <Base></Base>
  </React.StrictMode>
  ,
  root
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
