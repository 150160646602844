import * as React from "react";
// import Typography from "@material-ui/core/Typography";
import {Box, Grid, Container, Link, Typography} from "@mui/material";
import Stack from '@mui/material/Stack';
import {Divider} from '@mui/material';
// import { Item } from '@mui/material';
// import Container from "@mui/material/Container";
// import Typography from "@mui/material/Typography";
// import Link from "@mui/material/Link";
// import Grid from "@mui/material/Grid";
// import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import {Facebook, Instagram, Twitter, YouTube, EmojiTransportation, MailLock} from "@mui/icons-material";

import {
  BgDark,
  BgLight,
  AppBarDark,
  AppBarLight,
  FooterDark,
  FooterLight
} from './baseStyles'

export function FooterContent() {
  return <Container maxWidth="lg">

    {/*FIXME:*/}
    {/*@ts-ignore*/}
    <Grid item xs={12} sm={12} lg={12} align="center">
      <Typography mt={1}  mb={4}  variant="body2" color="textSecondary">The AI can make mistakes. Consider double checking important
        information.</Typography>

    </Grid>

    <Grid container spacing={5}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" color="text.primary" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {/*Building tools for videographers, independent filmmakers, and virtual cinematographers.*/}
          Bridging the gaps in online language learning. Building bridges between people.
          {/*Comprenders - get your language speaking practice.*/}

        </Typography>
      </Grid>

      <Grid item xs={12} sm={3}>

        <Typography variant="h6" color="text.primary" gutterBottom>
          Contact
        </Typography>

        <Typography mb={4} variant="body2" color="textSecondary" display="flex">
          <Link color="inherit"
                target="_blank"
                data-attr="footer:feedback_link"
                href="https://tothecut.youtrack.cloud/form/fb2603d4-9b3e-4e99-a775-c328e5dc2249">
            Support and feedback
          </Link>
        </Typography>

        <Typography variant="body2" color="textSecondary" display="flex">
          {/*Not: flex in the container - to avoid icon and email breaking to different lines*/}
          <MailLock style={{verticalAlign: "middle"}}/>
          info@ocutri.com
        </Typography>

        {/*<Typography variant="body2" color="textSecondary">*/}
        {/*    Phone: +1 000 000 0000*/}
        {/*</Typography>*/}

        <Typography variant="body2" color="textSecondary" display="flex"
        >
          {/*<EmojiTransportation style={{verticalAlign: "middle"}}/> */}
          Berlin, Germany
        </Typography>


        {/*<Box*/}
        {/*  mt={4}*/}
        {/*>*/}
        {/*  <Typography variant="body2" color="textSecondary" display="flex">*/}
        {/*    Version {process.env.REACT_APP_VERSION}*/}
        {/*  </Typography>*/}
        {/*</Box>*/}


      </Grid>
      {/*<Grid item xs={12} sm={2}>*/}
      {/*    <Typography variant="h6" color="text.primary" gutterBottom>*/}
      {/*        Meet Us*/}
      {/*    </Typography>*/}

      {/*    <Box*/}
      {/*        display="flex"*/}
      {/*        alignItems="center"*/}
      {/*    >*/}
      {/*        <Typography variant="body2" color="textSecondary" display="flex"*/}
      {/*        >*/}
      {/*            /!*<EmojiTransportation style={{verticalAlign: "middle"}}/> *!/*/}
      {/*            Berlin, Germany*/}
      {/*        </Typography>*/}
      {/*    </Box>*/}

      {/*</Grid>*/}
      <Grid item xs={12} sm={3}>
        {/*<Typography variant="h6" color="text.primary" gutterBottom>*/}
        {/*  Follow Us*/}
        {/*</Typography>*/}
        {/*<Link href="https://www.youtube.com/c/tothecut" color="inherit">*/}
        {/*  <YouTube/>*/}
        {/*</Link>*/}
        {/*<Link*/}
        {/*  href="https://www.instagram.com/prekrasny_zvetok/"*/}
        {/*  color="inherit"*/}
        {/*  sx={{pl: 1, pr: 1}}*/}
        {/*>*/}
        {/*  <Instagram/>*/}
        {/*</Link>*/}
      </Grid>
    </Grid>


    <Box
      // mt={4}
    >
      <Typography variant="body2" color="textSecondary" display="flex">
        Version {process.env.REACT_APP_VERSION}
      </Typography>
    </Box>
    <Box
      // mt={5}
    >
      <Typography variant="body2" color="textSecondary" align="left">
        {"Copyright © "}
        <Link color="inherit"
              data-attr="footer:copyright_site_link"
              href="https://comprenders.com/">
          Comprenders
        </Link>{" 2023-"}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Box>
    <Box
      mt={4}
    >
      <Typography variant="body2" color="textSecondary" align="center" component={'div'}>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem/>}
          spacing={2}
        >

          <Link color="inherit"
                data-attr="footer:toc_link"
                href="/terms.html">
            Terms of Service
          </Link>

          <Link color="inherit"
                data-attr="footer:privacy_link"
                href="/privacy.html">
            Privacy Policy
          </Link>

        </Stack>
      </Typography>
    </Box>
  </Container>;
}

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? FooterLight
            : FooterDark,
        p: 6,
      }}
    >
      <FooterContent sx={{m: 2}}/>
    </Box>
  );
}
