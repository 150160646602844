import Box from "@mui/material/Box";
import {containerStyle} from "./containerStyle";
import Typography from "@mui/material/Typography";
import React from "react";

function toTimeString(time: number) {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(time);
  return date.toTimeString().slice(3, 8);
}

export function TimeStamp(props: { time: number; loading?: boolean; show?: boolean }) {
  const {time, loading = false, show = true} = props;

  const defaultTimeStr = "00:00";
  const invalidTimeStr = "--:--";

  if (!show) {
    return null;
  }

  const timeStr = Number.isNaN(time) ? invalidTimeStr : toTimeString(time);

  return (
    <Box sx={containerStyle.timestamp}>
      <Typography>{loading ? defaultTimeStr : timeStr}</Typography>
    </Box>
  );
}
