import React, {useEffect, useRef, useState} from "react";
// import {ResponseMsg} from "../../models/ResponseMsg";
import {RequestMsg} from "../../models/RequestMsg";
import {useAppStore, useSettingsStore} from "../../state_store";
// import update from "immutability-helper";
import {chatMessageStyles} from "./chatMessageStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button, Stack, Tooltip} from "@mui/material";
import {MusicNote} from "@mui/icons-material";
import {PronunciationModal} from "../PronunciationModal";
import {AppSoundMode} from "../../state_store/appSoundMode";
import AudioPlayer from "../MuiAudioPlayer";
import {dateTimeToShortString} from "../uiUtils";


interface ChatMessageSentenceItemProps {
  children?: React.ReactNode; // best, accepts everything React can render

  /*
  FIXME: It is a string, yes, although atm back writes sentence index to the corresponsing field
   */
  // sentenceIdx: string; //number;
  // FIXME: 2 sources of truth for now: global sentences state and sentenceItem + parentRequest
  sentenceItem: RequestMsg;
  // parentRequest: RequestMsg;
  // requestId: number;
  vad: any; // FIXME: type
  // childrenElement: React.JSX.Element; // A single React element
  // style?: React.CSSProperties; // to pass through style props
  // onChange?: React.FormEventHandler<HTMLInputElement>; // form events! the generic parameter is the type of event.target
  // //  more info: https://react-typescript-cheatsheet.netlify.app/docs/advanced/patterns_by_usecase/#wrappingmirroring
  // props: Props & React.ComponentPropsWithoutRef<"button">; // to impersonate all the props of a button element and explicitly not forwarding its ref
  // props2: Props & React.ComponentPropsWithRef<MyButtonWithForwardRef>; // to impersonate all the props of MyButtonForwardedRef and explicitly forwarding its ref
  // /** array of a type! */
  // names: string[];
  // /** string literals to specify exact string values, with a union type to join them together */
  // status: "waiting" | "success";
  // /** an object with known properties (but could have more at runtime) */
  // obj: {
  //   id: string;
  //   title: string;
  // };
  // /** array of objects! (common) */
  // objArr: {
  //   id: string;
  //   title: string;
  // }[];
  // /** any non-primitive value - can't access any properties (NOT COMMON but useful as placeholder) */
  // obj2: object;
  // /** an interface with no required properties - (NOT COMMON, except for things like `React.Component<{}, State>`) */
  // obj3: {};
  // /** a dict object with any number of properties of the same type */
  // dict1: {
  //   [key: string]: MyTypeHere;
  // };
  // dict2: Record<string, MyTypeHere>; // equivalent to dict1
  // /** function that doesn't take or return anything (VERY COMMON) */
  // onClick: () => void;
  // /** function with named prop (VERY COMMON) */
  // onChange: (id: number) => void;
  // /** function type syntax that takes an event (VERY COMMON) */
  // onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // /** alternative function type syntax that takes an event (VERY COMMON) */
  // onClick(event: React.MouseEvent<HTMLButtonElement>): void;
  // /** any function as long as you don't invoke it (not recommended) */
  // onSomething: Function;
  // /** an optional prop (VERY COMMON!) */
  // optional?: OptionalType;
  // /** when passing down the state setter function returned by `useState` to a child component. `number` is an example, swap out with whatever the type of your state */
  // setState: React.Dispatch<React.SetStateAction<number>>;
}

export function ChatMessageSentenceItem(props: ChatMessageSentenceItemProps) {
  // NOTE: init with default values if needed
  const {
    vad,
    sentenceItem,
  } = props;

  const classes = chatMessageStyles();

  const [playing, setPlaying] = useState(sentenceItem.playing);

  const {
    stopChatPlayback,
    setAppSoundMode,
    playNextMessageIf,
    getMessageIsPlaying,
    chatMessagesIsPlaying,
    setChatMessagesPlaying,
    // currentChatMessagesSentencePlaying, setCurrentChatMessagesSentencePlaying
  } = useAppStore()

  const {
    displayResponseText, setDisplayResponseText,
    displayResponseTranslation, setDisplayResponseTranslation
  } = useSettingsStore()

  // function currentChatMessagesSentencePlaying(requestIdx, sentenceIdx) {
  //   const playing = useAppStore(s => s.currentChatMessages[requestIdx].responseSentences[sentenceIdx].playing);
  //   return playing;
  // }
  // const playing = currentChatMessagesSentencePlaying(requestIdx, sentenceIdx)

  // const playing0 = useAppStore(state => state.currentChatMessages[requestIdx].responseSentences[sentenceIdx].playing)

  // const [playing2, setPlaying] = useState(sentenceItem.playing);
  const [pronunciationIsOpen, pronunciationSetIsOpen] = useState(false);

  const ref = useRef();

  // FIXME:
  // @ts-ignore
  const executeScroll = () => ref.current?.scrollIntoView({behavior: "smooth", block: "end"})

  // const theme = useTheme();

  useEffect(() => {
    if (ref.current) {
      // ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
      executeScroll();
    }
  }, []);


  // useEffect(() => {
  //   // console.log('sentence item useEffect - Playing Has changed', playing)
  // }, [playing])

  // console.log('sentenceItem: ', sentenceItem)

  function pronunciationModalSetIsOpen(val: boolean) {
    if (val) {
      // console.log('Opening PronunciationModal')
      // stop playing anything
      stopChatPlayback()
      setAppSoundMode(AppSoundMode.pronunciation)

      vad.pause()

      // console.log('AppSoundMode set to:', useAppStore.getState().appSoundMode)
    } else {
      // console.log('Closing PronunciationModal')
      setAppSoundMode(AppSoundMode.chat)
      // console.log('AppSoundMode set to:', useAppStore.getState().appSoundMode)
    }

    pronunciationSetIsOpen(val)
  }


  function renderNormal() {
    return <Box className={classes.messageBlue}
      // sx={{color: theme.palette.secondary.contrastText}}
                ref={ref}
                id={'sent_' + sentenceItem.ts.getTime()}>

      {displayResponseText ?
        <Box>
          <Grid container spacing={0} columns={12}>
            <Grid item xs={2} sm={1}>
              {/*<Typography component="span" sx={{fontWeight: 'fontWeightSemiBold'}}>{sentenceIdx}</Typography>*/}
              <Typography component="span" sx={{fontWeight: 'fontWeightLight'}}>[{sentenceItem.lang}] </Typography>
            </Grid>
            <Grid item xs={10} sm={11}>
              <Box><Typography component="span"
              >{sentenceItem.requestText}</Typography></Box>

              {/*{sentenceItem.ipa ? <Box><Typography component="span"*/}
              {/*                                     sx={{fontWeight: 'fontWeightLight'}}>{sentenceItem.ipa}</Typography></Box> : null}*/}


              {sentenceItem.translation && displayResponseTranslation ?
                <Box><Typography component="span"
                                 sx={{fontWeight: 'fontWeightLight'}}>{sentenceItem.translation}</Typography></Box> : null}
            </Grid>
          </Grid>
        </Box>
        : null}

      <PronunciationModal isOpen={pronunciationIsOpen}
                          setIsOpen={pronunciationModalSetIsOpen}
                          lessonId={sentenceItem.lessonId}
                          vad={vad}
                          checkText={sentenceItem.requestText}
                          checkLang={sentenceItem.lang}
                          checkIpa={sentenceItem.ipa}
                          checkAudio={sentenceItem.urlEncodedWavRequest}/>

      <Stack direction="row" spacing={2}
             sx={{
               display: 'flex',
               // justifyContent: 'space-between',
               // alignItems: 'center',// p: 2
             }}
      >
        {/*FIXME: messes up internal spacing of MuiAudioPlayer. to fix that, we create a style for "wave" element and define margin*/}
        <Tooltip title={'Train pronunciation of this phrase'} enterDelay={300}>
          <Button size="small"
            // variant="outlined"
                  variant="contained"
                  onClick={() => pronunciationModalSetIsOpen(true)}
          >
            <MusicNote fontSize="small"/> Train
          </Button>
        </Tooltip>

        {sentenceItem.urlEncodedWavRequest ?
          <AudioPlayer inline
            // alignItems="center"
                       id={'pl_it_sent_' + sentenceItem.ts.getTime()}
                       src={sentenceItem.urlEncodedWavRequest}
                       showTimestamps={true}
                       display="waveform"
                       paperize={true}
            // playing={currentChatMessages[requestIdx].responseSentences[sentenceIdx].playing}
            //            playing={chatMessagesIsPlaying(sentenceItem)}
                       playing={getMessageIsPlaying(sentenceItem.ts.getTime())}
                       onPlay={() => {
                         // console.log('On play ', sentenceItem);

                         // do not play when in different modality
                         // ..

                         // sentenceItem.playing = true;

                         setPlaying(true);
                         sentenceItem.playing = true;
                       }}
                       onFinished={() => {
                         // console.log('On sentence finished playing: ', sentenceItem)
                         // console.log('Looking for next sentence after:', sentenceItem)
                         playNextMessageIf(sentenceItem)
                       }}
                       onPaused={() => {
                         // console.log('On paused ', sentenceItem)
                       }}
                       onNotPlaying={() => {
                         // console.log('On not playing ', sentenceItem)
                         // setChatMessagesPlaying(sentenceItem, false);

                         setPlaying(false);
                         sentenceItem.playing = false;
                       }}

          /> : null}

        {/*<Tooltip title={'Train pronunciation of this phrase'} enterDelay={300}>*/}
        {/*  <Button size="small"*/}
        {/*    // variant="outlined"*/}
        {/*          variant="contained"*/}
        {/*          onClick={() => pronunciationModalSetIsOpen(true)}*/}
        {/*  >*/}
        {/*    <MusicNote fontSize="small"/> Train*/}
        {/*  </Button>*/}
        {/*</Tooltip>*/}

        {/*<Typography component="span"*/}
        {/*            variant="body2"*/}
        {/*>{dateTimeToShortString(sentenceItem.ts.getTime())} </Typography>*/}
      </Stack>
      {/*</Stack>*/}
    </Box>;
  }

  function renderError() {
    return <Box className={`${classes.messageOrange} ${classes.messageError}`}
      // sx={{color: theme.palette.secondary.contrastText}}
                ref={ref}
                id={'req_' + sentenceItem.ts.getTime()}>
      <Box>
        <Typography
          component="span">{sentenceItem.error}</Typography>
      </Box>

    </Box>;
  }

  return sentenceItem.messageType === 'error' ? renderError() : renderNormal();
}
