import {Chat} from "../models/Chat";
import {useAppStore} from "../state_store";
import Typography from "@mui/material/Typography";
import React from "react";
import {Stack} from "@mui/material";
import {dateTimeToLongString} from "./uiUtils";

export function CurrentChatInfoTitle(props) {
  const chat: Chat = useAppStore.getState().currentChat
  let s = '[none]]'
  let sSmall = '[none]]'
  if (useAppStore.getState().currentChat) {
    s = `${chat.learnt_language} ${chat.language_level}, ${dateTimeToLongString(chat.created_at)}`
    sSmall = `${chat.learnt_language} ${chat.language_level}`
  } else {
    s = '(No chat selected)'
    sSmall = '(No chat)'
  }

  return <Stack direction="row" justifyContent="center">
    {/*<Typography*/}
    {/*  sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}*/}
    {/*>  Chats</Typography>*/}

    <Typography variant="body2" align="center"
                gutterBottom
                // verticalAlign="middle"
                sx={{m: 1, display: {xs: 'none', sm: 'none', md: 'block'}}}
    >
      {s}
    </Typography>

    <Typography variant="body2" align="center" gutterBottom
                // verticalAlign="middle"
                sx={{m: 1, display: {xs: 'block', sm: 'block', md: 'none'}}}
    >
      {sSmall}
    </Typography>
  </Stack>
}
