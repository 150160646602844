import {JsonProperty} from "json-object-mapper";

/**
 * usage: let responseMsg: CheckPronunciationResponse = ObjectMapper.deserialize(CheckPronunciationResponse, data);
 */
export class CheckPronunciationResponse {
  @JsonProperty({type: Number, name: "normalized_score"})
  normalized_score: number;
  @JsonProperty({type: Number, name: "score7"})
  score7: number;
  @JsonProperty({type: String, name: "checked_phrase_markup"})
  checked_phrase_markup: string;
  @JsonProperty({type: String, name: "correct_phrase_markup"})
  correct_phrase_markup: string;

  // @JsonIgnore()
  // isProcessingUserMessage: boolean;
}
