import React, {useEffect} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormGroup,
  Modal,
  Rating,
  Stack,
  TextField,
  Tooltip, IconButton,
  LinearProgress
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import MuiAudioPlayer from "mui-audio-player";
import Grid from "@mui/material/Grid";
import {VoiceInputButton} from "./VoiceInputButton";
import Fab from "@mui/material/Fab";
import {Send} from "@mui/icons-material";
import {useAppStore} from "../state_store";
import {AppSoundMode} from "../state_store/appSoundMode";
import {Star, Info} from '@mui/icons-material';
import {CheckPronunciationResponse} from "../models/CheckPronunciationResponse";
import {BackendRestApiInstance} from "../api/BackendRestApi";
import {notOkColor, okColor} from "./baseStyles";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {deleteAccount} from "./AuthDialogAndFunctions";
import Close from "@mui/icons-material/Close";
import AudioPlayer from "./MuiAudioPlayer";

// const modalStyle = {
//   position: 'absolute' as 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   // width: 400,
//   width: 'auto',
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

const labels: { [index: string]: string } = {
  0.0: 'Completely wrong',
  0.5: 'Almost completely wrong',
  1.0: 'You can do better',
  1.5: 'You can do better+',
  2.0: 'Poor',
  2.5: 'Poor+',
  3.0: 'Beyond average',
  3.5: 'Beyond average+',
  4.0: 'Ok',
  4.5: 'Ok+',
  5.0: 'Good',
  5.5: 'Good+',
  6.0: 'Very good',
  6.5: 'Excellent',
  7.0: 'Excellent+',
};

function getLabelText(value: number) {
  // return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

export function PronunciationModal(props) {
  const {
    // this one can be removed if needed
    lessonId,
    isOpen, setIsOpen, vad, checkText, checkLang, checkAudio, checkIpa, children, ...other
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // const {appSoundMode, setAppSoundMode} = useAppStore()
  const {
    setCurrentPronunciationCheckInputAudio,
    currentPronunciationCheckResults,
    setCurrentPronunciationCheckResults
  } = useAppStore()

  const currentPronunciationCheckInputAudio = useAppStore((state) => state.currentPronunciationCheckInputAudio);

  // let processedAudio = null;

  // const [value, setValue] = React.useState<number | null>(2);
  const [hover, setHover] = React.useState(-1);
  const [isProcessing, setIsProcessing] = React.useState(false);

  // const [isOpen, setIsOpen] = React.useState(false);
  // const handleOpen = () => {
  //   setIsProcessing(false);
  //   vad.pause()
  //   setIsOpen(true);
  // }

  const handleClose = () => {
    // TODO: should we consider the following corner case?
    //  audio was being recorded when closing the window. it will be sent
    // setAppSoundMode(AppSoundMode.default)

    // need to clean up the check results, so that when opening a dialog again (on a new audio or old one), it would not contain the old results
    setCurrentPronunciationCheckResults(null)
    setCurrentPronunciationCheckInputAudio(null)

    setIsOpen(false)
    setIsProcessing(false);
  };

  useEffect(() => {
    const callback = (state, prevState) => {
      if (!isOpen) {
        return;
      }

      if (state.currentPronunciationCheckInputAudio == prevState.currentPronunciationCheckInputAudio) {
        return;
      }

      if (!state.currentPronunciationCheckInputAudio) {
        return;
      }

      // processedAudio = state.currentPronunciationCheckInputAudio

      // console.log('PRONUNCIATION, updated to state', state)
      // console.log(state.currentPronunciationCheckInputAudio , prevState.currentPronunciationCheckInputAudio)

      setIsProcessing(true)
      BackendRestApiInstance.pronunciationCheck(lessonId, state.currentPronunciationCheckInputAudio, checkText, checkLang)
    }
    const unsubscribe = useAppStore.subscribe(
      callback,
      // (state) => getSceneObject(props.id)(state)
    )
    // callback()
    return unsubscribe
  }, [currentPronunciationCheckInputAudio])


  useEffect(() => {
    const callback = (state, prevState) => {
      if (!isOpen) {
        return;
      }

      if (currentPronunciationCheckResults) {
        setIsProcessing(false)
      }

      // vad.pause()
    }
    const unsubscribe = useAppStore.subscribe(
      callback,
      // (state) => getSceneObject(props.id)(state)
    )
    // callback()
    return unsubscribe
  }, [currentPronunciationCheckResults])


  return (

    <Dialog
      {...props}
      // size="xs"
      fullWidth
      maxWidth={props.maxWidth ?? 'xs'}

      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >

      {/*  <Modal*/}
      {/*  open={isOpen}*/}
      {/*  onClose={handleClose}*/}
      {/*  aria-labelledby="modal-modal-title"*/}
      {/*  aria-describedby="modal-modal-description"*/}
      {/*>*/}

      {/*<DialogTitle id="responsive-dialog-title">*/}
      {/*  <Tooltip*/}
      {/*    sx={{mb: 2}}*/}
      {/*    title={'Read the following text and check how well your pronunciation is understood. Your guides are the audio and the\n' +*/}
      {/*      'International Phonetic Alphabet transcription.'} enterDelay={300}>*/}
      {/*    <Typography id="modal-modal-title" variant="h6" component="h2">*/}
      {/*      Pronunciation training <Info fontSize="inherit"/>*/}
      {/*    </Typography>*/}
      {/*  </Tooltip>*/}
      {/*</DialogTitle>*/}

      <DialogTitle
        // style={{borderBottom: "1px solid #dee2e6"}}
        id="alert-dialog-title">

        <Box display="flex"
          // alignItems="center"
        >
          <Box flexGrow={1}>

            Pronunciation training

            {/*<Tooltip*/}
            {/*  sx={{mb: 2}}*/}
            {/*  title={'Read the following text and check how well your pronunciation is understood. Your guides are the audio and the\n' +*/}
            {/*    'International Phonetic Alphabet transcription.'} enterDelay={300}>*/}

            {/*  <Info fontSize="inherit"/>*/}
            {/*</Tooltip>*/}
          </Box>
          <Box>
            {/*<IconButton onClick={handleClose}>*/}
            <IconButton onClick={handleClose}>
              <Close/>
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      {/*<Box*/}
      {/*  // sx={modalStyle}*/}
      {/*>*/}

      {/*@ts-ignore*/}
      <DialogContent dividers display="flex" alignItems="center">
        {/*<Tooltip*/}
        {/*  sx={{mb: 2}}*/}
        {/*  title={'Read the following text and check how well your pronunciation is understood. Your guides are the audio and the\n' +*/}
        {/*    'International Phonetic Alphabet transcription.'} enterDelay={300}>*/}
        {/*  <Typography id="modal-modal-title" variant="h6" component="h2">*/}
        {/*    Pronunciation training <Info fontSize="inherit"/>*/}
        {/*  </Typography>*/}
        {/*</Tooltip>*/}

        <Typography id="modal-modal-description"
          // variant="h6"
          // sx={{mt: 2, mb: 2}}
        >
          Read the following text and check how well your pronunciation is understood. Your guides are the audio and the
          phonetic transcription:
        </Typography>


        <Stack direction="column"
               display="flex" alignItems="center">

          <Stack direction="row" spacing={2} sx={{mt: 2, mb: 2}}
                 display="flex" alignItems="center">
            <Grid container spacing={0} columns={12}>
              {/*<Grid item xs={2} sm={1}>*/}

              {/*  <Typography component="span" sx={{fontWeight: 'fontWeightLight'}}>[{checkLang}] </Typography>*/}

              {/*</Grid>*/}
              <Grid item xs={12} sm={12}>
                <Box>
                  {currentPronunciationCheckResults ?
                    <Typography
                      dangerouslySetInnerHTML={{__html: currentPronunciationCheckResults.checked_phrase_markup}}>
                    </Typography>
                    : <Typography component="span">{checkText}</Typography>
                  }
                </Box>
                {checkIpa ? <Box><Typography component="span"
                                             sx={{fontWeight: 'fontWeightLight'}}>{checkIpa}</Typography></Box> : null}
              </Grid>
            </Grid>
          </Stack>

          {checkAudio ?
            // @ts-ignore
            <AudioPlayer inline
                            id={'pronunciation_baseline'}
              // alignItems="center"
              //               id={'pl_it_sent_' + requestIdx + sentenceIdx}
                            src={checkAudio}
                            showTimestamps={true}
                            display="waveform"
                            paperize={true}
                            onNotPlaying={() => {
                            }}
            /> : null}

          {/*<Grid item xs={12} sm={12} lg={12}>*/}
          {/*  <Grid container sx={{display: 'flex', flexGrow: 1}}*/}
          {/*    // style={{padding: '20px'}}*/}
          {/*  >*/}
          {/*    <Grid item xs={12} align="center">*/}
          {/*      /!*  <ButtonGroup variant="outlined" aria-label="Basic button group" sx={{display: 'flex', flexGrow: 0}}>*!/*/}
          {/*      <VoiceInputButton vad={vad}/>*/}
          {/*      /!*  </ButtonGroup>*!/*/}
          {/*    </Grid>*/}

          {/*  </Grid>*/}
          {/*</Grid>*/}

          <Stack direction="row" spacing={2} sx={{mt: 2, mb: 2}}
                 display="flex" alignItems="center">

            <Typography id="modal-modal-description"
              // variant="h6"
              // sx={{mt: 2, mb: 2}}
            >
              Enable the microphone when ready:
            </Typography>
            <VoiceInputButton vad={vad}
                              variant="outlined"
            />
          </Stack>


          {/*{(isProcessing || currentPronunciationCheckResults) ?*/}
          {/*  <Typography variant="h6">*/}
          {/*  Your results*/}
          {/*</Typography>: null}*/}

          {isProcessing ? <LinearProgress/> : null}

          {currentPronunciationCheckResults ?
            <>

              <Stack direction="row" spacing={2} sx={{mt: 2, mb: 2}}
                     display="flex" alignItems="center">
                <Rating
                  name="hover-feedback"
                  max={7}
                  value={currentPronunciationCheckResults.score7}
                  precision={0.5}
                  getLabelText={getLabelText}
                  // onChange={(event, newValue) => {
                  //   setValue(newValue);
                  // }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={<Star style={{opacity: 0.55}} fontSize="inherit"/>}
                />

                {currentPronunciationCheckResults.score7 !== null && (
                  <Box
                    // component="span"
                    sx={{ml: 2}}>{labels[hover !== -1 ? hover : currentPronunciationCheckResults.score7]}</Box>
                )}
              </Stack>
            </> : null}

          {currentPronunciationCheckResults ?
            <>
              {currentPronunciationCheckResults.score7 !== null && currentPronunciationCheckResults.score7 >= 6.5 ?
                <Typography style={{color: okColor}}
                            dangerouslySetInnerHTML={{__html: currentPronunciationCheckResults.correct_phrase_markup}}>
                </Typography> :
                <Typography dangerouslySetInnerHTML={{__html: currentPronunciationCheckResults.correct_phrase_markup}}>
                </Typography>}
            </>
            : null}

          {currentPronunciationCheckInputAudio ?
            // @ts-ignore
            <AudioPlayer inline
              // alignItems="center"
                            id={'pronunciation_my_audio'}
                            src={currentPronunciationCheckInputAudio}
                            showTimestamps={true}
                            display="waveform"
                            paperize={true}
                            onNotPlaying={() => {
                            }}
            /> : null}

        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}
          // autoFocus
        >
          Close
        </Button>
      </DialogActions>

      {/*</Modal>*/}
    </Dialog>
  )
}
