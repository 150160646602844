import {Profile} from "../models/Profile";

// @ts-ignore
import defaultAvatar from "../res/cow_in_space.jpg";

export function profileImageUrl(data: Profile) {
  // data = JSON.stringify(data)
  // console.log(data)
  let photoURL = data.imageUrl;
  // Append size to the photo URL for Google hosted images to avoid requesting
  // the image with its original resolution (using more bandwidth than needed)
  // when it is going to be presented in smaller size.
  if (photoURL) {
    if ((photoURL.indexOf('googleusercontent.com') !== -1) ||
      (photoURL.indexOf('ggpht.com') !== -1)) {

      // https://developers.google.com/people/image-sizing
      photoURL = photoURL + '=s128-c';
      //document.getElementById('photo').clientHeight;
    }
  } else {
    photoURL = defaultAvatar;
  }

  return photoURL;
}


/***
 * Important: is meant as error handlers of <img> components
 * @param error
 */
export function replaceProfileImage(error) {
  // replacement of broken Image
  error.target.src = defaultAvatar;
}
