import {createSilentAudio} from "./utils/audioUtils";
import {utils} from "@ricky0123/vad-web";
import {RequestMsg} from "./models/RequestMsg";
//import {ResponseMsg} from "./models/ResponseMsg";
import {useAppStore} from "./state_store";
import update from "immutability-helper";
// import {ResponseMsg} from "./models/ResponseMsg";

export const SAMPLE_RATE = 16000
export const FRAME_SAMPLES = 1536
export const MIN_PAUSE_SEC = 2.2 // 3
export const FRAME_TIME_SEC = FRAME_SAMPLES / SAMPLE_RATE

export function prepareWav(audio: Float32Array) {
  const redemptionFrames = MIN_PAUSE_SEC / FRAME_TIME_SEC;
  const samplesToRemove = redemptionFrames * FRAME_SAMPLES;
  // console.log('removing ending silence samples', samplesToRemove, audio.length)

  let cutAudio: Float32Array = audio.slice(0, audio.length - samplesToRemove);
  let lenSec = cutAudio.length * FRAME_TIME_SEC / FRAME_SAMPLES;
  if (lenSec <= 1) {
    // console.log('Audio is too short. Growing.', lenSec)
    // server won't process files <= 1 sec length
    // this is a potential fix (adding 1 sec of blank audio before)
    // NOTE: untested
    // concatenation
    let silentAudio: Float32Array = createSilentAudio(1, 1, 16000);

    // console.log('Silence len:', silentAudio.length)
    cutAudio = Float32Array.of(...silentAudio, ...cutAudio);

    // or like this:
    // cutAudio = new Float32Array([...silentAudio, ...cutAudio]);

    lenSec = cutAudio.length * FRAME_TIME_SEC / FRAME_SAMPLES;
  }
  // console.log('new len, sec ', lenSec)
  // const wavBuffer = utils.encodeWAV(cutAudio)
  // NOTE: this conversion allows server to consume the data directly, reconversion on it's side
  const wavBuffer = utils.encodeWAV(cutAudio,//.slice(0, audio.length - samplesToRemove),
    1, // 1 - for 16 bit, 3 - for 31
    16000,
    1,
    16);

  const base64 = utils.arrayBufferToBase64(wavBuffer)

  return `data:audio/wav;base64,${base64}`;
}


export function updateChatPlayStatesOnNewSentenceArrived(old: Record<number, RequestMsg>,
                                                         // replyToMsgId: any,
                                                         newMsg: RequestMsg,
                                                         replyTo: RequestMsg) {
  // FIXME: move to state management

  // FIXME: also update to check the modalities (app states) - we have added the pronunciation state
  // console.log('updateChatPlayStatesOnNewSentenceArrived for new sentence, old messages', newMsg, old)

  let reqWasPlaying:boolean = replyTo.playing
  let respSentencePlaying:RequestMsg = null

  for (const [key, msg] of Object.entries(old)) {
    if (!respSentencePlaying && msg.replyToTsId && replyTo.ts && msg.replyToTsId.getTime() == replyTo.ts.getTime() && msg.playing) {
      respSentencePlaying = msg
    }

    // stop playing all of those
    replyTo.playing = false;

    // if (key != newRequestEntity.replyToTsId.getTime()) {
    //   // We consider playing only the new request under newRequestIdx and sentences within it.
    //   // We silence all the rest
    //   requestEntity.playing = false;
    //
    //   // old.forEach((sValue, sKey) => {
    //   //   sValue.playing = false;
    //   // });
    // } else {
    //   // We are already playing the chosen request entity - do not stop playing it
    //   if (newRequestEntity.playing) {
    //     console.log('curRequestEntity is playing: ', replyToMsgId, newMsg)
    //     return
    //     // continue
    //   }
    //
    //   // Request is not playing. Determine if any of the sentences are playing.
    //   let sentencePlaying = false;
    //
    //   const arr: Array<[string, ResponseMsg]> = Object.entries(newRequestEntity.responseSentences);
    //   for (const [sentenceIdx, sentenceEntity] of arr ) {
    //     if (sentenceEntity.playing) {
    //       // One is playing - stop and return. The sentence should continue playing
    //       console.log('curRequestEntity, sentence is already is playing: ', replyToMsgId, sentenceIdx, newMsg)
    //       sentencePlaying = true
    //       break
    //     }
    //   }
    //
    //   if (!sentencePlaying) {
    //     // No sentence or request are playing - start playing the newly arrived sentence
    //
    //     // NOTE: we have a choice if current response is not playing - start with the 1st or the just arrived sentence,
    //     // I think, the latter option is more reliable, needing less maintenance code. E.g., corner case:
    //     // all the prev sentences has been played already. We do not want to start from the beginning
    //
    //     console.log('Set playing sentence: ', replyToMsgId, newMsg)
    //     newMsg.playing = true;
    //   }
    // }
  }

  // restore what was playing or start playing an item
  if (reqWasPlaying) {
    replyTo.playing = true

    // console.log('updateChatPlayStatesOnNewSentenceArrived DECIDED to play replyTo', replyTo)
  } else if (respSentencePlaying) {
    respSentencePlaying.playing = true

    // console.log('updateChatPlayStatesOnNewSentenceArrived DECIDED to play respSentencePlaying (that was playing)', respSentencePlaying)
  } else {
    // start playing a new sentence
    newMsg.playing = true

    // console.log('updateChatPlayStatesOnNewSentenceArrived DECIDED to play newMsg (new sentence)', newMsg)
  }

  return old
}
