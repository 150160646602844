export function play_message_accepted_for_processing_notification() {
  (new Audio('./res/notification-done.mp3')).play();
}

export function play_cannot_accept_request_notification() {
  (new Audio('./res/mixkit-double-beep-tone-alert-2868.wav')).play();
}

export function play_processing_finished_notification() {
  (new Audio('./res/mixkit-confirmation-tone-2867.wav')).play();
}
