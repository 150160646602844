import * as React from 'react';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
// import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import {useChangeTheme} from './ThemeContext';
// import { useTranslate } from '@mui/docs/i18n';
import useLocalStorageState from '../utils/useLocalStorageState';
import {useTranslation} from "react-i18next";

function AppSettingsDrawer(props) {
  const { onClose, open = false, children, ...other } = props;
  // const t = useTranslate();y
  const { t, i18n } = useTranslation();
  // const upperTheme = useTheme();
  // const changeTheme = useChangeTheme();
  // const [mode, setMode] = useLocalStorageState('theme', 'system');
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // const preferredMode = prefersDarkMode ? 'dark' : 'light';

  // const handleChangeThemeMode = (event, paletteMode) => {
  //   if (paletteMode === null) {
  //     return;
  //   }
  //
  //   setMode(paletteMode);
  // };

  // React.useEffect(() => {
  //   const paletteMode = mode === 'system' ? preferredMode : mode;
  //
  //   console.log('changeTheme', paletteMode)
  //   changeTheme({ paletteMode });
  // }, [changeTheme, mode, preferredMode]);

  // const handleChangeDirection = (event, direction) => {
  //   if (direction === null) {
  //     direction = upperTheme.direction;
  //   }
  //
  //   changeTheme({ direction });
  // };

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={open}
      PaperProps={{
        elevation: 0,
        sx: { width: { xs: 310, sm: 360 }, borderRadius: '10px 0px 0px 10px' },
      }}
      {...other}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
        <Typography variant="body1" fontWeight="500">
          {t('settings.settings')}
        </Typography>
        <IconButton color="inherit" onClick={onClose} edge="end" aria-label={t('close')}>
          <CloseIcon color="primary" fontSize="small" />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ pl: 2, pr: 2 }}>
        {/*<Heading gutterBottom id="settings-mode">*/}
        {/*  {t('settings.mode')}*/}
        {/*</Heading>*/}
        {/*<ToggleButtonGroup*/}
        {/*  exclusive*/}
        {/*  value={mode}*/}
        {/*  color="primary"*/}
        {/*  onChange={handleChangeThemeMode}*/}
        {/*  aria-labelledby="settings-mode"*/}
        {/*  fullWidth*/}
        {/*>*/}
        {/*  <IconToggleButton*/}
        {/*    value="light"*/}
        {/*    aria-label={t('settings.light')}*/}
        {/*    data-ga-event-category="settings"*/}
        {/*    data-ga-event-action="light"*/}
        {/*  >*/}
        {/*    <LightModeIcon fontSize="small" />*/}
        {/*    {t('settings.light')}*/}
        {/*  </IconToggleButton>*/}
        {/*  <IconToggleButton*/}
        {/*    value="system"*/}
        {/*    aria-label={t('settings.system')}*/}
        {/*    data-ga-event-category="settings"*/}
        {/*    data-ga-event-action="system"*/}
        {/*  >*/}
        {/*    <SettingsBrightnessIcon fontSize="small" />*/}
        {/*    {t('settings.system')}*/}
        {/*  </IconToggleButton>*/}
        {/*  <IconToggleButton*/}
        {/*    value="dark"*/}
        {/*    aria-label={t('settings.dark')}*/}
        {/*    data-ga-event-category="settings"*/}
        {/*    data-ga-event-action="dark"*/}
        {/*  >*/}
        {/*    <DarkModeOutlinedIcon fontSize="small" />*/}
        {/*    {t('settings.dark')}*/}
        {/*  </IconToggleButton>*/}
        {/*</ToggleButtonGroup>*/}


        {/*<Heading gutterBottom id="settings-direction">*/}
        {/*  {t('settings.direction')}*/}
        {/*</Heading>*/}
        {/*<ToggleButtonGroup*/}
        {/*  exclusive*/}
        {/*  value={upperTheme.direction}*/}
        {/*  onChange={handleChangeDirection}*/}
        {/*  aria-labelledby="settings-direction"*/}
        {/*  color="primary"*/}
        {/*  fullWidth*/}
        {/*>*/}
        {/*  <IconToggleButton*/}
        {/*    value="ltr"*/}
        {/*    aria-label={t('settings.ltr')}*/}
        {/*    data-ga-event-category="settings"*/}
        {/*    data-ga-event-action="ltr"*/}
        {/*  >*/}
        {/*    <FormatTextdirectionLToRIcon fontSize="small" />*/}
        {/*    {t('settings.ltr')}*/}
        {/*  </IconToggleButton>*/}
        {/*  <IconToggleButton*/}
        {/*    value="rtl"*/}
        {/*    aria-label={t('settings.rtl')}*/}
        {/*    data-ga-event-category="settings"*/}
        {/*    data-ga-event-action="rtl"*/}
        {/*  >*/}
        {/*    <FormatTextdirectionRToLIcon fontSize="small" />*/}
        {/*    {t('settings.rtl')}*/}
        {/*  </IconToggleButton>*/}
        {/*</ToggleButtonGroup>*/}


        {/*<Heading gutterBottom>{t('settings.color')}</Heading>*/}
        {/*<Button*/}
        {/*  component="a"*/}
        {/*  href="/material-ui/customization/color/#playground"*/}
        {/*  data-ga-event-category="settings"*/}
        {/*  data-ga-event-action="colors"*/}
        {/*  size="medium"*/}
        {/*  variant="outlined"*/}
        {/*  fullWidth*/}
        {/*>*/}
        {/*  {t('settings.editWebsiteColors')}*/}
        {/*</Button>*/}

        {children}


      </Box>
    </Drawer>
  );
}

AppSettingsDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default AppSettingsDrawer;
