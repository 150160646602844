import React from "react";
import {styled, alpha} from '@mui/material/styles';
import Fab from "@mui/material/Fab";
import {AppBar, Box} from "@mui/material";
import {KeyboardDoubleArrowDown, KeyboardDoubleArrowUp} from "@mui/icons-material";
import {FooterContent} from "./Footer";

// FIXME: the styled fab adds vertical space
const StyledFab = styled(Fab)({
  zIndex: 1,

  // option 1 - original
  // position: 'absolute !important',
  // top: -40,
  // margin: '0 auto !important',

  // option 2 - experimental
  position: 'relative !important',
  // top: 'auto',
  // height: 'auto',
  margin: '-35px auto -20px auto !important',
  // top margin is 25px
  // right margin is 50px
  // bottom margin is 75px
  // left margin is 100px

  // top: 0,
  left: 0,
  right: 0,
  bottom: 0,

}as any );

const StyledBottomnAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'disablePermanent',
})(({
                                        //disablePermanent,
                                        theme}) => {
  return {
    // padding: theme.spacing(1, 2),
    // transition: theme.transitions.create('width'),
    // ...(disablePermanent && {
    //   boxShadow: 'none',
    // }),
    // ...(!disablePermanent && {
    //   [theme.breakpoints.up('lg')]: {
    //     // width: 'calc(100% - var(--MuiDocs-navDrawer-width))',
    //   },
    // }),
    // boxShadow: 'none',
    // backdropFilter: 'blur(8px)',
    borderStyle: 'solid',
    borderColor: (theme.vars || theme).palette.grey[100],
    // borderWidth: 0,
    // borderBottomWidth: 'thin',
    backgroundColor: 'rgba(255,255,255,0.8)',
    color: (theme.vars || theme).palette.grey[800],
    ...theme.applyDarkStyles({
      borderColor: alpha(theme.palette.primary[100], 0.08),
      backgroundColor: alpha(theme.palette.primaryDark[900], 0.8),
      color: (theme.vars || theme).palette.grey[500],
    }),

    // top: "auto",
    // bottom: 0,
    // textAlign:"center"
  };
});

export function BotomnSheet(props) {
  const {refBottomnSheet, showFooter, setShowFooter, children, ...other} = props

  // const [footerOpen, setFooterOpen] = React.useState(false);

  return <><StyledBottomnAppBar position="fixed" sx={{
    top: 'auto', bottom: 0, height: 'auto',
    // IMPORTANT to keep it at 1000 (not more, otherwise, bug, overlays popovers no matter what). See also: https://mui.com/material-ui/customization/z-index/
    zIndex: 1000,
  }}
                 ref={refBottomnSheet}
                 {...other}
  >

    {/*// option 1 - original*/}
    {/*<StyledFab color="secondary" aria-label="add" onClick={() => setShowFooter(prev => !prev)}>*/}
    {/*  <KeyboardDoubleArrowDown/>*/}
    {/*</StyledFab>*/}

    {children}

    {/*// option 2 - experimental*/}
    <StyledFab color="secondary"
               aria-label="Toggle Information in Footer"
               data-attr="toggle_footer"
               onClick={() => setShowFooter(prev => !prev)}>
      {showFooter ?  <KeyboardDoubleArrowUp/> :  <KeyboardDoubleArrowDown/>}

    </StyledFab>

    {showFooter && <Box sx={{m: 2}}><FooterContent/></Box>}

  </StyledBottomnAppBar>


  </>;
}
