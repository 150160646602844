import React, {Component} from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    Button,
    DialogTitle, IconButton,
} from "@mui/material";
import Close from '@mui/icons-material/Close';


function MyDialogComponent(props: any) {
    // const [show, setShow] = React.useState<boolean>(false)
    //
    // function handleClose() {
    //     setShow(false);
    // }

    function handleClose() {
        props.setShow(false)
    }

    return (
        <Dialog
            {...props}
            size="lg"
            // open={show}
            open={props.show}
            fullWidth
            maxWidth={props.maxWidth ?? 'md'}
            // onClose={handleClose}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle
              // style={{borderBottom: "1px solid #dee2e6"}}
                         id="alert-dialog-title">
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>{props.title}</Box>
                    <Box>
                        {/*<IconButton onClick={handleClose}>*/}
                        <IconButton onClick={handleClose}>
                            <Close/>
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                {props.children}
            </DialogContent>
        </Dialog>
    );
}

export default MyDialogComponent
