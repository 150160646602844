import React, {useEffect, useState} from "react";

// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import * as firebaseui from "firebaseui";
// import {CredentialHelper} from "firebaseui";

import {
  Alert,
  Dialog,
  DialogTitle,
  List,
  ListItem
} from "@mui/material";

import {Action, createService, isType} from "@rxfx/service";
import {useService, useWhileMounted} from "@rxfx/react";
import {authLoginMessage, authLogoutMessage, FSABus, loginRequestMessage} from "../event_bus";
import {useAppStore} from "../state_store";
import {BackendRestApiInstance} from "../api/BackendRestApi";
// import {StyledFirebaseAuth} from "./firebaseauthui";
import FirebaseAuth from "./firebaseauthui/FirebaseAuth";

export function signOut() {
  firebase.auth().signOut();
  // globalServices.authService.send('LOG_OUT')
}

export function deleteAccount() {
  if (!firebase.auth().currentUser) {
    return;
  }

  // @ts-ignore
  firebase.auth().currentUser.delete().catch(function (error) {
    if (error.code === 'auth/requires-recent-login') {
      // The user's credential is too old. They need to sign in again.
      firebase.auth().signOut().then(function () {
        // The timeout allows the message to be displayed after the UI has
        // changed to the signed out state.
        setTimeout(function () {
          // FIXME: show a nice popup here
          alert('Please sign in again to delete your account.');
        }, 1);
      });
    }
  });
}

/*
IMPORTANT: include <AuthDialog/> component in your app DOM. It wil display itself when needed
 */
export function AuthDialog() {
  const [loginModalShow, setLoginModalShow] = useState<boolean>(false);
  const [loginModalMessage, setLoginModalMessage] = useState<string>('Log into account');

  // const url = window.location.href;
  // const pathname = window.location.pathname;

  // console.log(window.location.pathname); //yields: "/js" (where snippets run)
  // console.log(window.location.href);     //yields: "https://stacksnippets.net/js"

  const {
    setLoginToken,
    setFirebaseApiUser,
    setApiUserInfo,
    setShowDashboardWindow
  } = useAppStore()

  // const [isSignedIn, setIsSignedIn] = useState<boolean>(false);

  const uiConfig: firebaseui.auth.Config = {
    // Popup signin flow rather than redirect flow.
    signInFlow: firebase.auth().isSignInWithEmailLink(window.location.href) ? 'redirect' : 'popup',

    // We will display Google and Facebook as auth providers.
    signInOptions: [
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID, // need permissions
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.GithubAuthProvider.PROVIDER_ID, // OK. TODO: request scopes: https://docs.github.com/en/developers/apps/building-oauth-apps/authorizing-oauth-apps

      // scopes: https://firebase.google.com/docs/auth/web/google-signin
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.EmailAuthProvider.PROVIDER_ID,
      {
        // see also: https://stackoverflow.com/questions/61513969/firebaseui-react-allow-user-to-sign-up-via-emailpassword-without-providing-the
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // Use email link authentication and do not require password.
        // Note this setting affects new users only.
        // For pre-existing users, they will still be prompted to provide their
        // passwords on sign-in.
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        // signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
        // Whether the display name should be displayed in Sign Up page.
        requireDisplayName: true,
        disableSignUp: {
          status: false
        },
        // Allow the user the ability to complete sign-in cross device, including
        // the mobile apps specified in the ActionCodeSettings object below.
        forceSameDevice: false,
        // Used to define the optional firebase.auth.ActionCodeSettings if
        // additional state needs to be passed along request and whether to open
        // the link in a mobile app if it is installed.

        emailLinkSignIn: function () {
          const isSSL = process.env.REACT_APP_BACKEND_IS_SSL !== '0' && process.env.REACT_APP_BACKEND_IS_SSL !== 'false' && process.env.REACT_APP_BACKEND_IS_SSL !== 'False';
          const schema = isSSL ? 'https' : 'http'
          const redirToUrl = `${schema}://${window.location.host}?completeLinkSignIn`
          // see more: https://firebase.google.com/docs/auth/web/email-link-auth?hl=en&authuser=7#web_1
          return {
            // Additional state showPromo=1234 can be retrieved from URL on
            // sign-in completion in signInSuccess callback by checking
            // window.location.href.
            // url: 'http://127.0.0.1/completeLinkSignIn?showPromo=1234',
            url: redirToUrl,
            // Custom FDL domain.
            // dynamicLinkDomain: 'example.page.link',
            // Always true for email link sign-in.
            handleCodeInApp: true,
            // // Whether to handle link in iOS app if installed.
            // iOS: {
            //     bundleId: 'com.example.ios'
            // },
            // // Whether to handle link in Android app if opened in an Android
            // // device.
            // android: {
            //     packageName: 'com.example.android',
            //     installApp: true,
            //     minimumVersion: '12'
            // }
          };
        }
      },
      // {
      //     provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      //     // Use email link authentication and do not require password.
      //     // Note this setting affects new users only.
      //     // For pre-existing users, they will still be prompted to provide their
      //     // passwords on sign-in.
      //     signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      //     // Allow the user the ability to complete sign-in cross device, including
      //     // the mobile apps specified in the ActionCodeSettings object below.
      //     forceSameDevice: false,
      //     // Used to define the optional firebase.auth.ActionCodeSettings if
      //     // additional state needs to be passed along request and whether to open
      //     // the link in a mobile app if it is installed.
      //
      //     // emailLinkSignIn: function() {
      //     //     return {
      //     //         // Additional state showPromo=1234 can be retrieved from URL on
      //     //         // sign-in completion in signInSuccess callback by checking
      //     //         // window.location.href.
      //     //         url: 'https://www.example.com/completeSignIn?showPromo=1234',
      //     //         // Custom FDL domain.
      //     //         dynamicLinkDomain: 'example.page.link',
      //     //         // Always true for email link sign-in.
      //     //         handleCodeInApp: true,
      //     //         // Whether to handle link in iOS app if installed.
      //     //         iOS: {
      //     //             bundleId: 'com.example.ios'
      //     //         },
      //     //         // Whether to handle link in Android app if opened in an Android
      //     //         // device.
      //     //         android: {
      //     //             packageName: 'com.example.android',
      //     //             installApp: true,
      //     //             minimumVersion: '12'
      //     //         }
      //     //     };
      //     // }
      // }
    ],
    // IMPORTANT! otherwise it will not allow to enter email but rather pick from the saved accounts
    // credentialHelper: firebase.auth.CredentialHelper.NONE,
    // credentialHelper: 'none',
    // Terms of service url.
    tosUrl: '/terms.html',
    // Privacy policy url.
    privacyPolicyUrl: '/privacy.html',
    // credentialHelper: "none", // firebaseui.auth.CredentialHelper.NONE, //'none',
    credentialHelper: firebaseui.auth.CredentialHelper.NONE, // firebaseui.auth.CredentialHelper.NONE, //'none',
    // CLIENT_ID && CLIENT_ID != 'YOUR_OAUTH_CLIENT_ID' ?
    //     firebaseui.auth.CredentialHelper.GOOGLE_YOLO :
    //     firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM,
    callbacks: {
      // Avoid redirects after sign-in.
      // signInSuccessWithAuthResult: () => false
      signInSuccessWithAuthResult: () => {
        setLoginModalShow(false);
        return false
      }
    }
  };

  useEffect(() => {
    /*
    HACK: this is a bit hacky. Please normalize this use case it and FirebaseAuth.tsx component
     */
    console.log('Auth isPendingRedirect check')

    // console.log('Auth. Have AuthUI?', Boolean(firebaseui.auth.AuthUI.getInstance()))

    // const ui = firebaseui.auth.AuthUI.getInstance()
    //   || new firebaseui.auth.AuthUI(firebase.auth());

    // if (ui.isPendingRedirect()) {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      console.log('Auth isPendingRedirect 1')

      setLoginModalShow(true)
      // // Render the firebaseUi Widget.
      // ui.start('#' + FIREBASE_AUTH_CONTAINER_ELEMENT_ID, uiConfig);
      // ui.start('#firebaseui-auth-container', uiConfig);

      // // The client SDK will parse the code from the link for you.
      // firebase.auth().signInWithEmailLink(auth, email, window.location.href)
      //   .then((result) => {
      //     // Clear email from storage.
      //     window.localStorage.removeItem('emailForSignIn');
      //     // You can access the new user by importing getAdditionalUserInfo
      //     // and calling it with result:
      //     // getAdditionalUserInfo(result)
      //     // You can access the user's profile via:
      //     // getAdditionalUserInfo(result)?.profile
      //     // You can check if the user is new or existing:
      //     // getAdditionalUserInfo(result)?.isNewUser
      //   })
      //   .catch((error) => {
      //     // Some error occurred, you can inspect the code: error.code
      //     // Common errors could be invalid email and invalid or expired OTPs.
      //   });
    }

    // This can also be done via:
    // if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    //   console.log('Auth isPendingRedirect 2')
    //   ui.start('#' + ELEMENT_ID, this.uiConfig);
    // }
    //

  }, []) // <-- empty dependency array

  useEffect(() => {
    console.log('Auth funcs inited')

    // firebase.auth().onAuthStateChanged(function(user) {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (signedUser) => {

        console.log('Auth state changed', signedUser)

        // this.setState({isSignedIn: !!signedUser})
        // setIsSignedIn(!!signedUser);
        setFirebaseApiUser(signedUser);

        if (signedUser) {
          // console.debug(signedUser);

          // @ts-ignore
          firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(onTokenArrived)//.then(fetchUserDataAPI)
            .catch(function (error) {
              // Handle error
            });

          //Once you have an ID token, you can send that JWT to your backend and validate it using the Firebase Admin SDK, or using a third-party JWT library if your server is written in a language which Firebase does not natively support.                console.debug("Auth user: ");

          // this.setState({isSignedIn: true, user: signedUser});
          // setIsSignedIn(true);

          // this.fetchUserDataAPI();
        } else {
          // setGlobal({userToken: null});
          // setUserToken(null);
          // FSABus.trigger(authLoginMessage({
          //     token: null
          // }))

          setLoginToken(null)

          setApiUserInfo(null)
          setFirebaseApiUser(null)
          setShowDashboardWindow(false)

          // setIsSignedIn(false);
          // this.setState({isSignedIn: false, user: {}});
          FSABus.trigger(authLogoutMessage({}))
        }
      }
    );

    // Now you either return just unregisterAuthObserver
    // which will be called when the component is unmounted
    return unregisterAuthObserver;
  }, []); // Important, pass an empty array so to execute useEffect hook only once

  useWhileMounted(() => {
    return FSABus.listen(//(i: TBusItem) => {return true},
      loginRequestMessage.match,
      (action) => {
        console.warn('RECEIVED: loginRequest event', action);
        if (isType(action, loginRequestMessage)) {
          console.warn('login request', action.type, action)

          // FIXME: do not need to have the state - remove the rest of the code
          // just show the window. todo. add text
          setLoginModalShow(true);
          if (action.payload.message) {
            setLoginModalMessage(action.payload.message);
          }

          return;
        }
      })
  })

  function onTokenArrived(idToken: string) {
    // console.info('onTokenArrived', idToken)

    FSABus.trigger(authLoginMessage({
      token: idToken
    }))

    setLoginToken(idToken);

    BackendRestApiInstance.fetchUserDataAPI();
  }

  function signUpWithLinkedIn() {
    // https://stackoverflow.com/questions/40040025/has-someone-managed-to-implement-a-linkedin-login-with-firebase-on-ios
    return firebase.auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        const provider = new firebase.auth.OAuthProvider('linkedin.com');
        provider.addScope('r_emailaddress');
        provider.addScope('r_liteprofile');
        firebase.auth()
          .signInWithPopup(provider)
          .then(result => {
            console.group('LinkedIn');
            console.log(result);
            console.groupEnd();
            return result;
          })
          .catch(error => {
            console.group('LinkedIn - Error');
            console.log(error)
            console.groupEnd();
            throw error;
          });

      });
  }

  return (
      <Dialog open={loginModalShow}
        // fullWidth={true}
        // maxWidth={undefined}
        // PaperProps={{
        //     sx: {
        //         width: "100%",
        //         maxWidth: "none"
        //     }
        // }}
              onClose={() => setLoginModalShow(false)}
      >
        <DialogTitle id="simple-dialog-title">Log in</DialogTitle>
        <div>
          {/*  <div>*/}
          {/*    You are currently accessing url {url}*/}
          {/*</div>*/}
          <List>
            <ListItem key='login_label'>
              <Alert variant="outlined"
                     severity="info"
              >{loginModalMessage}</Alert>
            </ListItem>

            <FirebaseAuth key='auth_button' uiConfig={uiConfig}
              // uiCallback={ui:firebaseui.auth.AuthUI => ui.disableAutoSignIn()}
                          firebaseAuth={firebase.auth()}/>
          </List>
        </div>
      </Dialog>
  )

}
