import * as React from 'react';
import {styled} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import MuiPopper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import MuiPaper from '@mui/material/Paper';
import {ClickAwayListener} from '@mui/base/ClickAwayListener';
import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiDivider from '@mui/material/Divider';
import {useTranslation} from "react-i18next";
import {BackendRestApiInstance} from "../api/BackendRestApi";
import {DeleteForever, PlayLesson} from "@mui/icons-material";
import {useAppStore} from "../state_store";
import {Button, Link, Stack} from "@mui/material";
import {dateTimeToLongString} from "./uiUtils";


const Paper = styled(MuiPaper)({
  transformOrigin: 'top right',
  backgroundImage: 'none',
});

const Popper = styled(MuiPopper)({
// The bottomn sheet has z==1200. It should not overlay oyr popover
  // see also: https://mui.com/material-ui/customization/z-index/
  // zIndex: 1601,
});


const List = styled(MuiList)(({theme}) => ({
  width: theme.spacing(40),
  maxHeight: 540,
  overflow: 'auto',
  padding: theme.spacing(1, 0),
}));

const ListItem = styled(MuiListItem)({
  display: 'flex',
  flexDirection: 'column',
});

const Loading = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  margin: theme.spacing(3, 0),
}));

const Divider = styled(MuiDivider)(({theme}) => ({
  margin: theme.spacing(1, 0),
}));

// function setChat(chat: Chat) {
//   useAppStore.getState().setCurrentChat(chat, null, true);
//   BackendRestApiInstance.getChatMessages(chat.id);
// }

export default function Chats() {
  const [open, setOpen] = React.useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  // const t = useTranslate();
  const {t, i18n} = useTranslation();
  // const userLanguage = useUserLanguage();
  // const [{ lastSeen, messages }, setNotifications] = React.useState({
  //   lastSeen: undefined,
  //   messages: undefined,
  // });

  const {chats} = useAppStore()

  // const messageList = messages
  //   ? messages
  //     .filter((message) => {
  //       if (
  //         // FIXME: not implemented - filter messages by language
  //         false
  //         // message.userLanguage &&
  //         // message.userLanguage !== userLanguage &&
  //         // message.userLanguage !== navigator.language.substring(0, 2)
  //       ) {
  //         return false;
  //       }
  //       return true;
  //     })
  //     .reverse()
  //   : null;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setTooltipOpen(false);

    if (process.env.NODE_ENV === 'development') {
      // Skip last seen logic in dev to make editing notifications easier.
      return;
    }

    // try {
    //   // Swallow the exceptions, e.g. rate limit
    //   BackendRestApiInstance.getChats();
    // } catch (e) {
    //
    // }

    // if (chats && chats.length > 0) {
    //   const newLastSeen = chats[0].id;
    //   setNotifications((notifications) => {
    //     if (newLastSeen !== notifications.lastSeen) {
    //       return {
    //         messages: notifications.messages,
    //         lastSeen: newLastSeen,
    //       };
    //     }
    //     return notifications;
    //   });
    //   document.cookie = `lastSeenNotification=${newLastSeen};path=/;max-age=31536000`;
    // }
  };

  React.useEffect(() => {
    let active = true;

    // Prevent search engines from indexing the notification.
    // if (/glebot/.test(navigator.userAgent) || messages) {
    //   return undefined;
    // }

    // Soften the pressure on the main thread
    // and create some distraction.
    const timeout = setTimeout(async () => {
      // try {
      //   // Swallow the exceptions, e.g. rate limit
      //   fetchChats();
      // } catch (e) {
      //
      // }
      // const notifications = await fetchChats().catch(() => {
      //   // Swallow the exceptions, e.g. rate limit
      //   return [];
      // });

      if (active) {
        // Permanent notifications
        const filteredNotifications = [
          /* {
            id: 0,
            title: "Let's translate!",
            text: '<a style="color: inherit;" target="_blank" rel="noopener" data-ga-event-category="l10n" data-ga-event-action="notification" data-ga-event-label="zh" href="https://crowdin.com/project/material-ui-docs">帮助 MUI 将文档翻译成中文</a>. 🇨🇳',
            userLanguage: 'zh',
          }, */
          {
            id: 1,
            text: 'You can <a style="color: inherit;" target="_blank" rel="noopener" href="https://twitter.com/MUI_hq">follow us on X</a> or subscribe on <a style="color: inherit;" target="_blank" rel="noopener" href="/blog/">our blog</a> to receive exclusive tips and updates about MUI and the React ecosystem.',
          },
          // Only 3
          // ...chats.splice(-3),
        ];

        // const seen = getCookie('lastSeenNotification');
        // const lastSeenNotification = seen === undefined ? 0 : parseInt(seen, 10);
        // setNotifications({
        //   messages: filteredNotifications,
        //   lastSeen: lastSeenNotification,
        // });
      }
    }, 1500);

    return () => {
      clearTimeout(timeout);
      active = false;
    };
  }, [chats]);

  let badgeContentCount = chats ? chats.length : 0;
  // let badgeContentCount = chats ? chats.reduce((count, message) => (message.id > lastSeen ? count + 1 : count), 0,) : 0;
  return (
    <React.Fragment>
      <Tooltip
        open={tooltipOpen}
        title={'Your chats'}
        enterDelay={300}
        onOpen={() => {
          setTooltipOpen(!open);
        }}
        onClose={() => {
          setTooltipOpen(false);
        }}
      >
        {/*<Button size="small" onClick={onNewChat}*/}
        {/*        startIcon={<VoiceChat fontSize="small"/>}*/}
        {/*        variant="outlined"*/}
        {/*  // IMPORTANT: the 'contained' style give a goof CTA vibe*/}
        {/*  // variant="contained"*/}
        {/*>*/}
        {/*  /!*<VoiceChat fontSize="small"/> *!/*/}
        {/*  New chat*/}
        {/*</Button>*/}

        <Badge color="error" badgeContent={badgeContentCount}>
          <Button
            sx={{mx: 1}}
            // color="primary"
            size="small"
            startIcon={<PlayLesson fontSize="small"/>}
            variant="outlined"

            ref={anchorRef}
            aria-controls={open ? 'chats-popup' : undefined}
            aria-haspopup="true"
            // aria-label={`${badgeContentCount} ${t('unreadNotifications')}`}
            data-ga-event-category="AppBar"
            data-ga-event-action="toggleChats"
            onClick={handleToggle}
          >
            {/*<Badge color="error" badgeContent={badgeContentCount}>*/}
            {/*  <PlayLesson fontSize="small"/>*/}

            {/*  Chats*/}
            {/*</Badge>*/}

            <Typography
              sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}
            > Chats</Typography>

          </Button>
        </Badge>
      </Tooltip>

      <Popper
        id="notifications-popup"
        anchorEl={anchorRef.current}
        open={open}
        placement="bottom-end"
        transition
        disablePortal
        role={undefined}
      >
        {({TransitionProps}) => (
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false);
            }}
          >
            <Grow in={open} {...TransitionProps}>
              <Paper
                sx={(theme) => ({
                  mt: 0.5,
                  border: '1px solid',
                  borderColor: 'grey.200',
                  boxShadow: `0px 4px 20px rgba(170, 180, 190, 0.3)`,
                  ...theme.applyDarkStyles({
                    borderColor: 'primaryDark.700',
                    boxShadow: `0px 4px 20px rgba(0, 0, 0, 0.5)`,
                  }),
                })}
              >
                <List>
                  {chats && chats.length > 0 ? (
                    chats.map((chat, index) => (
                      <React.Fragment key={chat.id}>
                        <ListItem alignItems="flex-start">

                          {/*learnt_language: str*/}
                          {/*native_language: str*/}
                          {/*language_level: str*/}
                          {/*voice_id: str*/}
                          {/*created_at: Optional[datetime] = None*/}
                          {/*chat_type: str = 'default'*/}

                          <Stack direction="row" spacing={2}>
                            <>
                              {/*FIXME: https://github.com/mui/material-ui/issues/13921#issuecomment-484133463*/}
                              {/*@ts-ignore*/}
                              <Typography gutterBottom
                                          component="button"
                                          size="small"
                                          onClick={() => {
                                            // setChat(chat);
                                            useAppStore.getState().setCurrentChat(chat, null, true);
                                          }}
                              >
                                {/*<span> {chat.learnt_language} {chat.language_level} - {chat.chat_type}</span>*/}
                                <span> {chat.learnt_language} {chat.language_level}</span>
                              </Typography>

                              <Typography variant="caption" color="text.secondary">
                                {dateTimeToLongString(chat.created_at)}
                              </Typography>
                            </>

                            <Tooltip
                              title="Delete forever. Use it often even if your plan allows unlimited storage."
                            >
                              <Link component="button"
                                    onClick={() => BackendRestApiInstance.deleteChat(chat.id)}
                              >
                                <DeleteForever color="warning"/>
                              </Link>
                            </Tooltip>
                          </Stack>


                        </ListItem>

                        {index < chats.length - 1 ? <Divider/> : null}
                      </React.Fragment>
                    ))
                  ) : (
                    // <Loading>
                    //   <CircularProgress size={32}/>
                    // </Loading>
                    <Typography align="center" component={'div'}>You have no chats yet.</Typography>
                  )}
                </List>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  );
}
