// import {useTothecutStore} from "../state_store";
import {fetchApi, HttpError} from "./utils/fetchApi";
import {useAppStore, useSettingsStore} from "../state_store";
import {AUTH_VERIFY_API_URL, CHAT_API_URL, PRONUNCIATION_CHECK_API_URL} from "./ApiEndpoints";
import {showPopupInfoMessage} from "../components/PopupNotifications";
import {ObjectMapper} from "json-object-mapper";
import "reflect-metadata";
import {Chat} from "../models/Chat";
import {RequestMsg} from "../models/RequestMsg";
import {CheckPronunciationResponse} from "../models/CheckPronunciationResponse";
import {Profile} from "../models/Profile";
import {FSABus, loginRequestMessage} from "../event_bus";


export const setLastChatAsCurrent = (chats) => {
  // console.log('on app load', chats)
  if (chats === undefined || chats.length == 0) {
    return
  }

  // FIXME: we rely on backend sorting order here
  useAppStore.getState().setCurrentChat(chats[0], null, true);
}

function onAuthError(httpStatusCode: number) {
  // NOTE: not showing error notifications
  FSABus.trigger(loginRequestMessage({message: 'To continue, please login. If you do not have an account, it will be created.',}))
}

function onNotEnoughFundsError(httpStatusCode: number) {
  // NOTE: not showing error notifications
  // useAppStore.getState().setPaymentDialogOpen(true)
  useAppStore.getState().setShowDashboardWindow(true)
  // showPopupInfoMessage(`${errorMsg}\n${error}`, 'error')
}

function defaultErrorProc(error) {
  if ((error instanceof HttpError) && (error.httpCode === 401 || error.httpCode === 403)) {
    onAuthError(error.httpCode);
    return true;
  } else if ((error instanceof HttpError) && (error.httpCode === 402)) {
    onNotEnoughFundsError(error.httpCode);
    return true;
  }

  return false;
}


/*
TODO: use axios? use react query?
 */
class BackendRestApi {
  pronunciationCheck(lesson_id: string, wav_data: string, correct_phrase: string, checkLang: string) {
    // TODO: force schema on chat data
    let onSuccess = (data: any) => {
      if (data) {
        // console.debug('pronunciationCheck got data', data)

        let results: CheckPronunciationResponse = ObjectMapper.deserialize(CheckPronunciationResponse, data);
        useAppStore.getState().setCurrentPronunciationCheckResults(results);
      }
    };

    let onError = (error: any) => {
      if (defaultErrorProc(error))
        return;

      const errorMsg = 'Error checking pronunciation. Please reload the page.'
      showPopupInfoMessage(`${errorMsg}\n${error}`, 'error')
    };

    // console.debug('pronunciationCheck API', useAppStore.getState().currentPronunciationCheckResults)

    fetchApi(PRONUNCIATION_CHECK_API_URL,
      {
        lesson_id: lesson_id,
        wav_data: wav_data,
        correct_phrase: correct_phrase,
        lang: checkLang
      },
      'Pronunciation score calculated',
      onSuccess, onError,
      'POST',
      {
        token: useAppStore.getState().loginToken,
      },
      // onAuthError
    );
  }

  deleteChat(id: string) {
    let onSuccess = (data: any) => {
      // if (data) {
      //   // update the chat list
      //   this.getChats();
      //   //actualizeMap(appId, showPopupInfoMessage);
      // }
      showPopupInfoMessage(`Chat deleted`, 'info')

      if (useAppStore.getState().currentChat.id && useAppStore.getState().currentChat.id === id) {
        useAppStore.getState().setCurrentChat(null, null, false);
        useAppStore.getState().setCurrentChatMessages(null);
      }

      // refetch the chat list
      // this.getChats();

      this.getChats(
        useSettingsStore.getState().fetchLastChatOnChatDeletion ? setLastChatAsCurrent : null
      );

      // TODO: if current chat has been deleted - update

      // NOTE: as it is an important task, we display the popup
    };

    let onError = (error: any) => {
      if (defaultErrorProc(error))
        return;

      const errorMsg = 'Error deleting chat. Please reload the page.'
      showPopupInfoMessage(`${errorMsg}\n${error}`, 'error')
    };

    // console.debug('delete chat API')

    fetchApi(`${CHAT_API_URL}/${id}`,
      null,
      'Chat deleted',
      onSuccess, onError,
      'DELETE',
      {
        token: useAppStore.getState().loginToken,
      },
      // onAuthError
    );
  }

  getChats(onSuccessCb = null) {
    // TODO: force schema on chat data
    let onSuccess = (data: any) => {
      if (data) {
        // console.debug('getChats got data', data)

        // paginated
        // FIXME: fetch all
        let chats: Chat[] = ObjectMapper.deserializeArray(Chat, data.data);
        useAppStore.getState().setChats(chats);

        if (onSuccessCb) {
          onSuccessCb(chats)
        }
      }
    };

    let onError = (error: any) => {
      if (defaultErrorProc(error))
        return;

      const errorMsg = 'Error fetching chats. Please reload the page.'
      showPopupInfoMessage(`${errorMsg}\n${error}`, 'error')
    };
    // console.debug('fetch chats API')

    // FIXME: fetch all
    fetchApi(`${CHAT_API_URL}?offset=0&limit=100`,
      // fetchApi( `${CHAT_CREATE_URL}?offset=0&limit=1`, // sorted - from newest
      null,
      // {
      //   offset: 0,
      //   limit: 1000
      // },
      'Chat list received',
      onSuccess, onError,
      'GET',
      {
        token: useAppStore.getState().loginToken,
      },
      // onAuthError
    );
  }

  createChat(learnt_language: string, language_level: string, native_language: string, voice_id: string, chat_type: string = 'default', firstMessage = null) {
    // TODO: force schema on chat data
    let onSuccess = (data: any) => {
      if (data) {
        // console.debug('createChatAPI got data', data)

        let chat: Chat = ObjectMapper.deserialize(Chat, data);
        useAppStore.getState().setCurrentChat(chat, null, false);
        this.getChatMessages(chat.id)

        // if (firstMessage) {
        //
        // }

        // sync the data
        this.getChats(null);
      }
    };

    let onError = (error: any) => {
      if (defaultErrorProc(error))
        return;

      const errorMsg = 'Error creating a chat. Please login and reload the page.'
      showPopupInfoMessage(`${errorMsg}\n${error}`, 'error')
    };

    // console.debug('createChatAPI', useAppStore.getState().currentChat)

    fetchApi(CHAT_API_URL,
      {
        chat_type: chat_type,
        learnt_language: learnt_language,
        language_level: language_level,
        native_language: native_language,
        voice_id: voice_id
      },
      'Lesson created',
      onSuccess, onError,
      'POST',
      {
        token: useAppStore.getState().loginToken,
      },
      // onAuthError
    );
  }

  getChatMessages(chatId: string) {
    // TODO: force schema on chat data
    let onSuccess = (data: any) => {
      if (data) {
        console.debug('getChatMessages got data', data)

        // paginated
        // FIXME: fetch all
        // TODO: set current chat as one from messages? or trust it had been set already?
        const messages: RequestMsg[] = ObjectMapper.deserializeArray(RequestMsg, data.data);

        console.log('getChatMessages mapped data', messages)

        // this piece breaks the app:
        const offset = data.offset;
        const total = data.total;
        const batchLen = messages ? messages.length : 0;

        const numMessagesNotLoaded = Math.max(total - batchLen, 0)

        console.log(`Message downloaded: ${batchLen}, offset: ${offset}, total: ${total}, numMessagesNotLoaded ${numMessagesNotLoaded}`, messages)
        // 10, 0, 29
        // FIXME: messages are taken from chrono start and further. correct behaviour - taken from chrono end. NOTE: the display order is correct

        useAppStore.getState().setCurrentChatMessagesNotLoaded(numMessagesNotLoaded);

        let newMap = {}
        messages.reverse().forEach((element) => {
          newMap[element.ts.getTime()] = element;
        });
        useAppStore.getState().setCurrentChatMessages(newMap);
        useAppStore.getState().setChatMessagesBeingLoaded(false);
      }
    };

    let onError = (error: any) => {
      if (defaultErrorProc(error))
        return;

      const errorMsg = 'Error fetching chat messages. Please reload the page.'
      showPopupInfoMessage(`${errorMsg}\n${error}`, 'error')
      useAppStore.getState().setChatMessagesBeingLoaded(false);
    };
    // console.debug('fetch chat messages API')

    // FIXME: implement virtualization to be able to show full history
    // const limit=10;
    const MESSAGE_LIMIT = 50;
    useAppStore.getState().setChatMessagesBeingLoaded(true);

    // IMPORTANT: that does not control the amount of responses (or response sentences) to this chat message.
    fetchApi(`${CHAT_API_URL}/${chatId}/messages?offset=0&limit=${MESSAGE_LIMIT}`,
      null,
      // {
      //   offset: 0,
      //   limit: 1000
      // },
      'Chat messages received',
      onSuccess, onError,
      'GET',
      {
        token: useAppStore.getState().loginToken,
      },
      // onAuthError
    );
  }

  fetchUserDataAPI() {
    let on_success = (data: any) => {
      if (data) {
        let profile: Profile = ObjectMapper.deserialize(Profile, data);

        // console.debug('profile', profile)

        useAppStore.getState().setApiUserInfo(profile);
      }

      // TODO: fetch last chat?
      // sync the data
      this.getChats(useSettingsStore.getState().fetchLastChatOnPageRefresh ? setLastChatAsCurrent : null);
    };

    let onError = (error: any) => {
      if (defaultErrorProc(error))
        return;

      const errorMsg = 'Error fetching your account information. Please reload the page.'
      showPopupInfoMessage(`${errorMsg}\n${error}`, 'error')
    };

    // console.debug('fetchUserDataAPI', useAppStore.getState().loginToken)

    fetchApi(AUTH_VERIFY_API_URL,
      {
        // verificationType: 'customer',
        token: useAppStore.getState().loginToken,
        // appToken: null
      },
      'Account information loaded',
      on_success, onError,
      'POST', null,
      // onAuthError
    );
  }

}

const BackendRestApiInstance = new BackendRestApi();
export {BackendRestApiInstance}

