import React from "react";
import {BackendRestApiInstance} from "../api/BackendRestApi";
import Box from "@mui/material/Box";
import {Button, FormControl, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {ChatInputBar} from "./ChatInputBar";
import {useAppStore} from "../state_store";
import Typography from "@mui/material/Typography";
import ReactCountryFlag from "react-country-flag"
import {langNameToCode} from "./LangCodes";

export function ChatCreationForm() {
  /*
    learnt_language: str
    language_level: str
    native_language: str
    voice_id: str

    Russian, French, German, English, Spanish
    A1, A2, B1, B2, C1, C2

    TODO: inherit/extend to be the pronunciation lesson - add topic selection/input control - or get it from the chat conponent
 */
  const [creatingChat, setCreatingChat] = React.useState(false);

  const [languageToLearn, setLanguageToLearn] = React.useState('German');
  const [level, setLevel] = React.useState('B1');
  const [nativeLanguage, setNativeLanguage] = React.useState('English');
  const [teacher, setTeacher] = React.useState('female');
  // const {
  //   currentChatMessages,
  //   setCurrentChatMessages,
  //   currentChat,
  //   setCurrentChat,
  //   currentChatType,
  //   setCurrentChatType
  // } = useAppStore()


  const createChat = () => {
    // {isProcessing ? <LinearProgress/> : null}
    // console.debug('createChat on click')
    setCreatingChat(true);
    if (useAppStore.getState().currentChatType == 'pronunciation') {
      const firstMessage = null;
      BackendRestApiInstance.createChat(languageToLearn, level, nativeLanguage, teacher, 'pronunciation',
        firstMessage);
    } else {
      BackendRestApiInstance.createChat(languageToLearn, level, nativeLanguage, teacher, 'default');
    }
  }

  return <Box alignItems="center"
              sx={{p: 2}}
              style={{
                minHeight: '100%', height: '100%',
                // width: '100%',
                // maxWidth
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                justifyContent: "center"
              }}>
    <Typography variant='h2'
                sx={{m: 1}}>
      New lesson</Typography>
    <FormControl variant="standard" sx={{m: 1, minWidth: 160}}>
      <InputLabel id="learnt-language-label">
        {/*<ReactCountryFlag*/}
        {/*  countryCode={langNameToCode[languageToLearn.toLowerCase()].toUpperCase()}*/}
        {/*  style={{*/}
        {/*    margin: '10px'*/}
        {/*  }}*/}
        {/*  aria-label={languageToLearn}*/}
        {/*/>*/}

        {/*<Typography>*/}
        Language to learn
        {/*</Typography>*/}
      </InputLabel>

      <Select
        labelId="learnt-language-label"
        // id="demo-simple-select-standard"
        value={languageToLearn}
        onChange={(event: SelectChangeEvent) => setLanguageToLearn(event.target.value)}
        label="Language to learn"
      >
        <MenuItem value={'English'}>English</MenuItem>
        <MenuItem value={'German'}>German</MenuItem>
        <MenuItem value={'French'}>French</MenuItem>
        <MenuItem value={'Spanish'}>Spanish</MenuItem>
        <MenuItem value={'Italian'}>Italian</MenuItem>
        <MenuItem value={'Portuguese'}>Portuguese</MenuItem>
        <MenuItem value={'Turkish'}>Turkish</MenuItem>
        <MenuItem value={'Arabic'}>Arabic</MenuItem>
        <MenuItem value={'Hebrew'}>Hebrew</MenuItem>
        <MenuItem value={'Czech'}>Czech</MenuItem>
        <MenuItem value={'Polish'}>Polish</MenuItem>
        <MenuItem value={'Romanian'}>Romanian</MenuItem>
        <MenuItem value={'Russian'}>Russian</MenuItem>
        <MenuItem value={'Ukrainian'}>Ukrainian</MenuItem>
        <MenuItem value={'Belarusian'}>Belarusian</MenuItem>
        <MenuItem value={'Bulgarian'}>Bulgarian</MenuItem>
        <MenuItem value={'Hungarian'}>Hungarian</MenuItem>
        <MenuItem value={'Serbian'}>Serbian</MenuItem>
        <MenuItem value={'Dutch'}>Dutch</MenuItem>
        <MenuItem value={'Latvian'}>Latvian</MenuItem>
        <MenuItem value={'Swedish'}>Swedish</MenuItem>
        <MenuItem value={'Greek'}>Greek</MenuItem>
        <MenuItem value={'Indonesian'}>Indonesian</MenuItem>
        <MenuItem value={'Urdu'}>Urdu</MenuItem>
        <MenuItem value={'Punjabi'}>Punjabi</MenuItem>
        <MenuItem value={'Hindi'}>Hindi</MenuItem>
        <MenuItem value={'Tamil'}>Tamil</MenuItem>

      </Select>
    </FormControl>

    <FormControl variant="standard" sx={{m: 1, minWidth: 80}}>
      <InputLabel id="level-label">Level</InputLabel>
      <Select
        labelId="level-label"
        // id="demo-simple-select-standard"
        value={level}
        onChange={(event: SelectChangeEvent) => setLevel(event.target.value)}
        label="Level"
      >
        <MenuItem value={'A1'}>A1 - basic</MenuItem>
        <MenuItem value={'A2'}>A2 - better</MenuItem>
        <MenuItem value={'B1'}>B1 - good</MenuItem>
        <MenuItem value={'B2'}>B2 - very good</MenuItem>
        <MenuItem value={'C1'}>C1 - excellent</MenuItem>
        <MenuItem value={'C2'}>C2 - near native</MenuItem>
      </Select>
    </FormControl>

    <FormControl variant="standard" sx={{m: 1, minWidth: 160}}>
      <InputLabel id="native-language-label">
        {/*<ReactCountryFlag*/}
        {/*  countryCode={langNameToCode[nativeLanguage.toLowerCase()].toUpperCase()}*/}
        {/*  style={{*/}
        {/*    margin: '10px'*/}
        {/*  }}*/}
        {/*  aria-label={nativeLanguage}*/}
        {/*/>*/}

        Your native language</InputLabel>
      <Select
        labelId="native-language-label"
        // id="demo-simple-select-standard"
        value={nativeLanguage}
        onChange={(event: SelectChangeEvent) => setNativeLanguage(event.target.value)}
        label="Your native language"
      >
        <MenuItem value={'English'}>English</MenuItem>
        <MenuItem value={'German'}>German</MenuItem>
        <MenuItem value={'French'}>French</MenuItem>
        <MenuItem value={'Spanish'}>Spanish</MenuItem>
        <MenuItem value={'Russian'}>Russian</MenuItem>
        <MenuItem value={'Ukrainian'}>Ukrainian</MenuItem>
        <MenuItem value={'Italian'}>Italian</MenuItem>
        <MenuItem value={'Portuguese'}>Portuguese</MenuItem>
        <MenuItem value={'Turkish'}>Turkish</MenuItem>
        <MenuItem value={'Arabic'}>Arabic</MenuItem>
        <MenuItem value={'Hebrew'}>Hebrew</MenuItem>
        <MenuItem value={'Czech'}>Czech</MenuItem>
        <MenuItem value={'Polish'}>Polish</MenuItem>
        <MenuItem value={'Romanian'}>Romanian</MenuItem>
        <MenuItem value={'Russian'}>Russian</MenuItem>
        <MenuItem value={'Ukrainian'}>Ukrainian</MenuItem>
        <MenuItem value={'Belarusian'}>Belarusian</MenuItem>
        <MenuItem value={'Bulgarian'}>Bulgarian</MenuItem>
        <MenuItem value={'Hungarian'}>Hungarian</MenuItem>
        <MenuItem value={'Serbian'}>Serbian</MenuItem>
        <MenuItem value={'Dutch'}>Dutch</MenuItem>
        <MenuItem value={'Latvian'}>Latvian</MenuItem>
        <MenuItem value={'Swedish'}>Swedish</MenuItem>
        <MenuItem value={'Greek'}>Greek</MenuItem>
        <MenuItem value={'Indonesian'}>Indonesian</MenuItem>
        <MenuItem value={'Urdu'}>Urdu</MenuItem>
        <MenuItem value={'Punjabi'}>Punjabi</MenuItem>
        <MenuItem value={'Hindi'}>Hindi</MenuItem>
        <MenuItem value={'Tamil'}>Tamil</MenuItem>
      </Select>
    </FormControl>

    <FormControl variant="standard" sx={{m: 1, minWidth: 160}}>
      <InputLabel id="teacher-label">Teacher</InputLabel>
      <Select
        labelId="teacher-label"
        // id="demo-simple-select-standard"
        value={teacher}
        onChange={(event: SelectChangeEvent) => setTeacher(event.target.value)}
        label="Teacher"
      >
        <MenuItem value={'female'}>female</MenuItem>
        <MenuItem value={'male'}>male</MenuItem>
      </Select>
    </FormControl>

    <Typography sx={{m: 1, maxWidth: 160}}>You will be able to communicate via speaking or typing.</Typography>

    {/*{currentChatType != 'default' ? <ChatInputBar vad={vad}/> : null}*/}

    {creatingChat ?
      <LinearProgress sx={{m: 1, minWidth: 160}}/>
      :
      <Button onClick={createChat}
              sx={{m: 1, minWidth: 160}}
        // variant="outlined"
        // IMPORTANT: the 'contained' style give a goof CTA vibe
              variant="contained"
      >
        {useAppStore.getState().currentChatType == 'pronunciation' ? 'Start pronunciation training' : 'Start'}
      </Button>
    }
  </Box>

}
