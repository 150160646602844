import {JsonIgnore, JsonProperty} from "json-object-mapper";
// import {ResponseMsg} from "./ResponseMsg";

/**
 * usage: let responseMsg: ResponseMsg = ObjectMapper.deserialize(ResponseMsg, data);
 */
export class Chat {
  @JsonProperty({type: String, name: "learnt_language"})
  learnt_language: string;
  @JsonProperty({type: String, name: "native_language"})
  native_language: string;
  @JsonProperty({type: String, name: "language_level"})
  language_level: string;
  @JsonProperty({type: String, name: "voice_id"})
  voice_id: string;

  @JsonProperty({type: String, name: "id"})
  id: string;
  @JsonProperty({type: Date})
  created_at: Date;

  @JsonProperty({type: String, name: "chat_type"})
  chat_type: string;

  @JsonIgnore()
  isProcessingUserMessage: boolean;
}
