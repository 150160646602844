import i18n from 'i18next';
// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import en_translation from './en/translation.json';
import ru_translation from './ru/translation.json';
import {initReactI18next} from 'react-i18next';


export const resources = {
  en: {
    translation: en_translation
  },
  ru: {
    translation: ru_translation
  },
} as const;

i18n
  // .use(ICU)
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    lng: 'en',
    // commenting it out fixes inability to use nested translation json values
    // keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

  });
