import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export const Heading = styled(Typography)(({theme}) => ({
  margin: '20px 0 10px',
  color: theme.palette.grey[600],
  fontWeight: 700,
  fontSize: theme.typography.pxToRem(11),
  textTransform: 'uppercase',
  letterSpacing: '.08rem',
}));
