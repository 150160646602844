import {styled} from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";

export const IconToggleButton = styled(ToggleButton)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  '& > *': {
    marginRight: '8px',
  },
});
