import * as React from 'react';
import { ThemeProvider, useTheme } from '@mui/material/styles';
// import { useTheme } from '@mui/material/styles';
// import { ThemeProvider } from './ThemeContext';
import { brandingDarkTheme, brandingLightTheme } from './brandingTheme';
import useMediaQuery from "@mui/material/useMediaQuery";

interface BrandingProviderProps {
  children: React.ReactNode;
  /**
   * If not `undefined`, the provider is considered nesting and does not render NextNProgressBar & CssBaseline
   */
  mode: 'light' | 'dark' | 'system';
}

export default function BrandingProvider(props: BrandingProviderProps) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
  const preferredMode = prefersDarkMode ? 'dark' : 'light';

  const { children, mode: modeProp } = props;
  const upperTheme = useTheme();
  let mode = modeProp || upperTheme.palette.mode;

  // see also: https://mui.com/material-ui/customization/dark-mode/
  // const theme = mode === 'dark' ? brandingDarkTheme : brandingLightTheme;

  // console.log('mode', mode)

  if (mode !== 'dark' && mode !== 'light') {
    // handling 'system' mode
    mode = preferredMode;
  }
  // console.log('mode final', mode)

  const theme = mode === 'dark' ? brandingDarkTheme : brandingLightTheme;

  // console.log('theme', theme)

  return <ThemeProvider theme={modeProp ? () => theme : theme}>{children}</ThemeProvider>;
  // return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

// export function useChangeTheme() {
//   const dispatch = React.useContext(DispatchContext);
//   return React.useCallback((options) => dispatch({ type: 'CHANGE', payload: options }), [dispatch]);
// }
