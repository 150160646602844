import {useAppStore} from "../state_store";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from "@mui/material";
import React from "react";
import {deleteAccount} from "./AuthDialogAndFunctions";
import {useTranslation} from "react-i18next";

export default function AccountDeletionDialog() {
  const {
    accountDeletionDialogOpen,
    setAccountDeletionDialogOpen,
    apiUserInfo,
  } = useAppStore()

  const {t, i18n} = useTranslation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setAccountDeletionDialogOpen(true);
  };

  const handleClose = () => {
    setAccountDeletionDialogOpen(false);
  };

  function renderNoLogin() {
    return (
      <>
        <DialogTitle id="responsive-dialog-title">
          {t('accountDeletion.accountDeletion')}
        </DialogTitle>
        <DialogContent>
          <div style={{height: "100%", width: "100%"}}>
            {t('accountDeletion.youNeedToLoginToDeleteYourAccountWeNeedItToVerifyY')}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {t('accountDeletion.close')}
          </Button>
        </DialogActions>
      </>
    )
  }

  function renderHaveLogin() {
    return (
      <>
        <DialogTitle id="responsive-dialog-title">
          {t('accountDeletion.accountDeletion')}
        </DialogTitle>
        <DialogContent>

          <Alert severity="warning">
            <Typography>{t('accountDeletion.youAreAboutToDeleteTheAccountAndAssociatedDataWhen')}</Typography>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteAccount} color="error">
            {t('accountDeletion.deleteMyAccount')}
          </Button>
          <Button onClick={handleClose} autoFocus>
            {t('accountDeletion.close')}
          </Button>
        </DialogActions>
      </>
    )
  }

  // function renderContentNormal() {
  //   return  <DialogTitle id="responsive-dialog-title">
  //     ACCOUNT DELETION
  //   </DialogTitle>
  //   <DialogContent>
  //
  //     <Alert severity="warning">
  //       <Typography>You are about to delete the account and associated date</Typography>
  //     </Alert>
  //   </DialogContent>
  //   <DialogActions>
  //     <Button  onClick={deleteAccount} color="error">
  //       DELETE MY ACCOUNT
  //     </Button>
  //     <Button onClick={handleClose} autoFocus>
  //       Close
  //     </Button>
  //   </DialogActions>
  //
  // }

  return (

    <Dialog
      fullScreen={fullScreen}
      open={accountDeletionDialogOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      {apiUserInfo ? renderHaveLogin() : renderNoLogin()}

    </Dialog>

  );
}
