import React, {useEffect, useRef, useState} from "react";
import {WaveForm, WaveSurfer} from "wavesurfer-react";

import {useTheme} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import {changeCurrentTimeForTimeline, initializeForTimeline} from "./timelineImpl";
import {getInitializeWaveSurfer, plugins, WaveSurferRef} from "./waveSurferImpl";
import {PlayPauseButton} from "./PlayPauseButton";
import {AudioPlayerProps} from "./audioPlayerProps";
import {TimeStamp} from "./TimeStamp";

export default function AudioPlayer(props: AudioPlayerProps) {
  const {
    src,
    id = "waveform",
    display = "waveform",
    inline = false,
    paperize = true,
    waveColor,
    waveHeight = 48,
    showTimestamps = true,
    playPauseIconButtonProps,
    containerSx,
    containerHeight = "auto",
    containerWidth = 250,
    size,

    onNotPlaying,
    onPlay,
    onFinished,
    onPaused,
    playing,
    setPlaying,
    ...other
  } = props;

  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  // const [playing, setPlaying] = useState(false);
  // const [finish, setFinish] = useState(false);
  const [position, setPosition] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(
    null
  );
  const waveSurferRef = useRef<WaveSurferRef>(null);

  const handlePlay = () => {
    if (display === "timeline" && audioElement) {
      playOrPauseForTimeline(playing, audioElement);

      return null;
    }

    // console.log('handlePlay')
    playOrPauseForWaveForm(waveSurferRef);
  };

  const handlePlayState = (play:boolean) => {
    // console.log('handlePlayState')
    if (display === "timeline" && audioElement) {

      if (audioElement.paused) {
        // console.log('playOrPauseForWaveForm: currentWaveSurfer.pause()')
        if (play)
          audioElement.play();
      } else {
        if (!play)
          audioElement.pause();
      }

      return;
    }

    const currentWaveSurfer = waveSurferRef.current;
    if (!currentWaveSurfer)
      return;

    if (currentWaveSurfer?.isPlaying()) {
      // console.log('playOrPauseForWaveForm: currentWaveSurfer.pause()')
      if (!play)
        currentWaveSurfer?.pause();
    } else {
      if (play)
        currentWaveSurfer?.play();
    }
  };

  function playOrPauseForTimeline(
    playing: boolean,
    audioElement: HTMLAudioElement | null
  ) {
    playing ? audioElement?.pause() : audioElement?.play();
  }

  function playOrPauseForWaveForm(
    waveSurferRef: React.MutableRefObject<WaveSurferRef>
  ) {
    const currentWaveSurfer = waveSurferRef.current;
    if (!currentWaveSurfer) return null;

    if (playing) {
      // console.log('playOrPauseForWaveForm: currentWaveSurfer.pause()')
      currentWaveSurfer.pause();
      return null;
    }

    // console.log('playOrPauseForWaveForm: currentWaveSurfer.play()')
    currentWaveSurfer.play();
  }

  useEffect(() => {
    if (display !== "timeline") return;

    initializeForTimeline({
      src,
      audioElement,
      setAudioElement,
      setLoading,
      setCurrentTime,
      setEndTime,
      setPosition,
      setPlaying,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, display]);

  useEffect(
    () => () => {
      audioElement?.remove();
      waveSurferRef.current?.destroy();
    },
    []
  );

  useEffect(() => {
    // console.log('useEffect - Playing Has changed', playing)

    // handlePlay()
    handlePlayState(playing)
  },[playing])

  const theme = useTheme();
  const _waveColor = waveColor || theme.palette.primary.light;
  const progressColor = theme.palette.primary.main;
  const mergedContainerStyle = {
    height: containerHeight,
    width: containerWidth,

    ...(containerSx || {}),
  };

  return (
    <Stack
      {...other}
      sx={mergedContainerStyle}
      direction={inline ? "row" : "column"}
      component={paperize ? Paper : "div"}
      alignItems="center"
    >
      {inline ? (
        <PlayPauseButton
          disabled={loading}
          // display={display}
          // audioElement={audioElement}
          playing={playing}
          handlePlay={handlePlay}
          // waveSurferRef={waveSurferRef}
          playPauseIconButtonProps={{
            size: size,
            ...playPauseIconButtonProps,
          }}
        />
      ) : null}
      {loading ? (
        <LinearProgress variant="determinate" value={progress} />
      ) : null}
      <Stack
        component={Box}
        direction="row"
        flexGrow={loading ? 0 : 1}
        height="100%"
        width="100%"
        alignItems="center"
        spacing={1}
      >
        <TimeStamp time={currentTime} loading={loading} show={showTimestamps} />
        <Box flexGrow={1} height="100%" width="100%" alignItems="center">
          {display === "waveform" && (
            <WaveSurfer
              onMount={getInitializeWaveSurfer({
                src,
                waveSurferRef,
                setLoading,
                setCurrentTime,
                setEndTime,
                setProgress,
                setPlaying,
                // setFinish,
                playing,
                onNotPlaying,
                onPlay,
                onFinished,
                onPaused
              })}
              plugins={plugins}
            >
              <WaveForm
                id={id}
                fillParent
                mediaControls
                waveColor={_waveColor}
                progressColor={progressColor}
                height={waveHeight}
                hideScrollbar={false}
              />
            </WaveSurfer>
          )}
          {display === "timeline" && !loading && (
            <Box mx={1} display="flex" alignItems="center" height="100%">
              <Slider
                onChange={changeCurrentTimeForTimeline(audioElement)}
                size={size === "large" ? `medium` : size ?? "small"}
                value={position}
              />
            </Box>
          )}
        </Box>
        <TimeStamp time={endTime} loading={loading} show={showTimestamps} />
      </Stack>
      {!inline ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <PlayPauseButton
            disabled={loading}
            // display={display}
            // audioElement={audioElement}
            playing={playing}
            handlePlay={handlePlay}
            // waveSurferRef={waveSurferRef}
            playPauseIconButtonProps={playPauseIconButtonProps}
          />
        </Box>
      ) : null}
    </Stack>
  );
}

