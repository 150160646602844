import {format, isValid, parseISO} from "date-fns";

export function formatDate(date: Date) {
  const isValidDate = isValid(date);
  if (!isValidDate) {
    // return 'Invalid date: ' + dateStr
    return 'Invalid date'
  }
  return format(date, 'dd.MM.yyyy HH:mm:ss');
}

export function formatDateStr(dateStr: string) {
  const parsedDate: Date = parseISO(dateStr);
  return formatDate(parsedDate);
}

export function dateTimeToLongString(dt) {
  return new Date(dt).toLocaleDateString(
    window.navigator.language, //'en-US',
    {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function dateTimeToShortString(dt) {
  return new Date(dt).toLocaleDateString(
    window.navigator.language, //'en-US',
    {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
}

// export function date_to_local_date_str(healthCurrentDate) {
//     return format(healthCurrentDate, 'dd.MM.yyyy HH:mm');
// }
//
// export function api_date_to_local_str(date) {
//     let healthCurrentDate = parseISO(date)
//     //let healthCurrentDate = new Date(this.global.health_data.CurrentTime);
//     return date_to_local_date_str(healthCurrentDate);
// }
