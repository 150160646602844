import React, {useEffect, useRef, useState} from 'react';
// import PropTypes from 'prop-types';
// import { useRouter } from 'next/router';
import GlobalStyles from '@mui/material/GlobalStyles';
import {styled, alpha} from '@mui/material/styles';
// import NProgress from 'nprogress';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import {SettingsOutlined, GitHub, PlayLesson, MenuSharp} from '@mui/icons-material';
// import NProgressBar from '@mui/docs/NProgressBar';
import {debounce} from '@mui/material/utils';
// import NextLink from 'next/link';
import SvgHamburgerMenu from './SvgHamburgerMenu';
// import AppNavDrawer from 'docs/src/modules/components/AppNavDrawer';
import AppSettingsDrawer from './AppSettingsDrawer';
import Notifications from './Notifications';
import Chats from "./Chats";
import {useTranslation} from "react-i18next";
// import MarkdownLinks from 'docs/src/modules/components/MarkdownLinks';
// import SkipLink from 'docs/src/modules/components/SkipLink';
// import PageContext from 'docs/src/modules/components/PageContext';
// import { useTranslate } from '@mui/docs/i18n';
// import SvgMuiLogomark from './SvgMuiLogomark';
import AppFrameBanner from './AppFrameBanner';
import {Avatar, Grid, Link, Typography} from "@mui/material";
// import {ChatMessagesContainer} from "./chat/ChatMessagesContainer";
// import Logo from "../assets/icon_lang_256_transp.png";
// import Logo from "../assets/icon_2.png";
import Logo from "../assets/logo_256.png";

// const nProgressStart = debounce(() => {
//   NProgress.start();
// }, 200);
//
// function nProgressDone() {
//   nProgressStart.clear();
//   NProgress.done();
// }

// export function NextNProgressBar() {
//   const router = useRouter();
//   React.useEffect(() => {
//     const handleRouteChangeStart = (url, {shallow}) => {
//       if (!shallow) {
//         nProgressStart();
//       }
//     };
//
//     const handleRouteChangeDone = (url, {shallow}) => {
//       if (!shallow) {
//         nProgressDone();
//       }
//     };
//
//     router.events.on('routeChangeStart', handleRouteChangeStart);
//     router.events.on('routeChangeComplete', handleRouteChangeDone);
//     router.events.on('routeChangeError', handleRouteChangeDone);
//
//     return () => {
//       router.events.off('routeChangeStart', handleRouteChangeStart);
//       router.events.off('routeChangeComplete', handleRouteChangeDone);
//       router.events.off('routeChangeError', handleRouteChangeDone);
//     };
//   }, [router]);
//
//   return <NProgressBar/>;
// }

// const sx = {minWidth: {sm: 160}};

// const AppSearch = React.lazy(() => import('docs/src/modules/components/AppSearch'));
//
// export function DeferredAppSearch() {
//   const [mounted, setMounted] = React.useState(false);
//   React.useEffect(() => {
//     setMounted(true);
//   }, []);
//
//   return (
//     <React.Fragment>
//       {/* Suspense isn't supported for SSR yet */}
//       {mounted ? (
//         <React.Suspense fallback={<Box sx={sx} />}>
//           <AppSearch sx={sx} />
//         </React.Suspense>
//       ) : (
//         <Box sx={sx} />
//       )}
//     </React.Fragment>
//   );
// }

const RootDiv = styled('div')(({theme}) => {
  return {
    // NOTE: commented out since it brakes the layout of grids in children
    // display: 'flex',
    ...theme.applyDarkStyles({
      background: (theme.vars || theme).palette.primaryDark[900],
    }),
    // TODO: Should be handled by the main component
  };
});

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'disablePermanent',
})(({disablePermanent, theme}) => {
  return {
    padding: theme.spacing(1, 2),
    transition: theme.transitions.create('width'),
    ...(disablePermanent && {
      boxShadow: 'none',
    }),
    ...(!disablePermanent && {
      [theme.breakpoints.up('lg')]: {
        // width: 'calc(100% - var(--MuiDocs-navDrawer-width))',
      },
    }),
    boxShadow: 'none',
    backdropFilter: 'blur(8px)',
    borderStyle: 'solid',
    borderColor: (theme.vars || theme).palette.grey[100],
    borderWidth: 0,
    borderBottomWidth: 'thin',
    backgroundColor: 'rgba(255,255,255,0.8)',
    color: (theme.vars || theme).palette.grey[800],
    ...theme.applyDarkStyles({
      borderColor: alpha(theme.palette.primary[100], 0.08),
      backgroundColor: alpha(theme.palette.primaryDark[900], 0.8),
      color: (theme.vars || theme).palette.grey[500],
    }),

    // top: "auto",
    // bottom: 0,
    // textAlign:"center"
  };
});

const GrowingDiv = styled('div')({
  flex: '1 1 auto',
});

const NavIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'disablePermanent',
})(({disablePermanent, theme}) => {
  if (disablePermanent) {
    return {};
  }
  return {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  };
});

// const StyledAppNavDrawer = styled(AppNavDrawer)(({ disablePermanent, theme }) => {
//   if (disablePermanent) {
//     return {};
//   }
//   return {
//     [theme.breakpoints.up('lg')]: {
//       flexShrink: 0,
//       width: 'var(--MuiDocs-navDrawer-width)',
//     },
//   };
// });

export const HEADER_HEIGHT = 64;

export function AppFrame(props) {
  // const { children, disableDrawer = false, className, BannerComponent = AppFrameBanner } = props;
  const {
    children,
    additionalHeaderLeftContent,
    additionalHeaderButtons,
    disableDrawer = false,
    className,
    setAppBarHeight,
    BannerComponent = AppFrameBanner,
    menuButtonAdditionalContent,
    settingsAdditionalChildren = null
  } = props;
  // const t = useTranslate();
  const {t, i18n} = useTranslation();

  const refAppBar = useRef();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [settingsOpen, setSettingsOpen] = React.useState(false);

  // const { activePage } = React.useContext(PageContext);

  // const disablePermanent = activePage?.disableDrawer === true || disableDrawer === true;
  const disablePermanent = disableDrawer === true;

  useEffect(() => {
    if (!refAppBar.current) return;
    const resizeObserver = new ResizeObserver(() => {
      // console.log('size changed', refBottomnSheet.current.offsetHeight)
      // Do what you want to do when the size of the element changes
      if (setAppBarHeight)
        setAppBarHeight(refAppBar.current.offsetHeight);
    });
    resizeObserver.observe(refAppBar.current);
    return () => resizeObserver.disconnect(); // clean up

  }, []);

  return (
    <RootDiv className={className}>
      {/*<NextNProgressBar />*/}
      <CssBaseline/>
      {/*<SkipLink />*/}
      {/*<MarkdownLinks />*/}
      <StyledAppBar disablePermanent={disablePermanent}
                    ref={refAppBar}
      >
        <GlobalStyles
          styles={{
            ':root': {
              '--MuiDocs-header-height': `${HEADER_HEIGHT}px`,
            },
          }}
        />
        <Toolbar variant="dense" disableGutters>

          <Grid container columns={12} rowSpacing={1} columnSpacing={0}
            // justifyContent="center"
                alignItems="center"
            // justifyContent="flex-end"
          >

            {/*logo and additional items by the logo*/}
            <Grid item xs={5} sm={3} lg={2}
                  justifyContent="start"
                  alignItems="center"
            >
              <Stack direction="row"
                // spacing="10px"
                     container justifyContent="start"
                     alignItems="center">
                <Box
                  display="flex"
                  justifyContent="center"
                >

                  {/*<NavIconButton*/}
                  {/*  edge="start"*/}
                  {/*  color="primary"*/}
                  {/*  // aria-label={t('appFrame.openDrawer')}*/}
                  {/*  aria-label='Open menu'*/}
                  {/*  disablePermanent={disablePermanent}*/}
                  {/*  onClick={() => setMobileOpen(true)}*/}
                  {/*  sx={{ml: '1px'}}*/}
                  {/*>*/}
                  {/*  <SvgHamburgerMenu/>*/}
                  {/*</NavIconButton>*/}

                  <Link href="/"
                    // passHref
                        legacybehavior="true"
                        data-attr="home_logo_link"
                    // aria-label={t('goToHome')}
                        aria-label='Home'
                  >
                    <Box
                      // component="a"
                      // aria-label={t('goToHome')}
                      // aria-label='Home'
                      sx={{display: 'flex', ml: 0, mr: 1}}
                      justifyContent="center"
                      // sx={{ display: { md: 'flex', lg: 'none'}, ml: 2 }}
                    >
                      {/*<SvgMuiLogomark width={30}/>*/}

                      <Avatar
                        variant="rounded"
                        alt="Comprenders - Your on-call language teacher"
                        src={Logo}
                        sx={{width: 48, height: 48}}
                      />
                      {/*<Typography*/}
                      {/*variant="h4"*/}
                      {/*>Comprenders</Typography>*/}
                      {/*Comprenders AI*/}

                      {/*<Box*/}
                      {/*  component="img"*/}
                      {/*  sx={{*/}
                      {/*    height: 32,*/}
                      {/*  }}*/}
                      {/*  alt="Logo."*/}
                      {/*  src={Logo}*/}
                      {/*/>*/}

                    </Box>
                  </Link>
                </Box>

                <>{additionalHeaderLeftContent}</>

              </Stack>
            </Grid>

            {/*<Grid item xs={true} lg={true}>*/}
            {/*    /!*a spacer*!/*/}
            {/*</Grid>*/}

            {/*items in the main (middle) space of the app bar*/}
            {/*<Grid item xs={12} sm={true} lg={true} order={{xs: 3, sm: 2, lg: 2}}>*/}

            {/*NOTE:  If breakpoint is 'true', the grid item's width grows to use the space available in the grid container.*/}
            <Grid item xs={12} sm={true} lg={true}
                  order={{xs: 3, sm: 2, lg: 2}}
                  alignItems="center"
                  justifyContent="space-around"
                  display='flex'
                  flexDirection='column'
            >
              <>{additionalHeaderButtons}</>
            </Grid>

            {/*menu icon, and additional items by it - like the login widget area*/}
            {/*<Grid item xs={true} sm={4} lg={1} order={{xs: 2, sm: 2, lg: 3}}>*/}
            <Grid item xs={true} sm={3} lg={2} order={{xs: 2, sm: 3, lg: 3}}>
              <Stack direction="row" spacing="10px" container
                     alignItems="center"
                     justifyContent="end">
                {/*<>{additionalHeaderButtons}</>*/}
                {BannerComponent ? BannerComponent : null}
                {/*<BannerComponent />*/}
                {/*<DeferredAppSearch />*/}
                {/*<Tooltip title={'GitHub'} enterDelay={300}>*/}
                {/*  <IconButton*/}
                {/*    component="a"*/}
                {/*    color="primary"*/}
                {/*    // href={process.env.SOURCE_CODE_REPO}*/}
                {/*    data-ga-event-category="header"*/}
                {/*    data-ga-event-action="github"*/}
                {/*  >*/}
                {/*    <PlayLesson fontSize="small" />*/}
                {/*  </IconButton>*/}
                {/*</Tooltip>*/}

                <Notifications/>

                <Stack direction="row"
                       alignItems="center"
                >
                  {menuButtonAdditionalContent ? menuButtonAdditionalContent : null}

                  <Tooltip title={'Toggle Menu'} enterDelay={300}>
                    <IconButton
                      color="primary"
                      data-attr="menu_button"
                      onClick={() => setSettingsOpen(true)}
                      // variant="outlined"
                      size="large"
                      disableRipple={true}
                      // sx={{px: '0px'}}
                    >
                      {/*<SettingsOutlined fontSize="small"/>*/}
                      <MenuSharp
                        fontSize="small"
                      />
                      {/*Settings*/}
                    </IconButton>
                  </Tooltip>
                </Stack>


              </Stack>
            </Grid>
          </Grid>

          {/*<NavIconButton*/}
          {/*  edge="start"*/}
          {/*  color="primary"*/}
          {/*  // aria-label={t('appFrame.openDrawer')}*/}
          {/*  aria-label='Open menu'*/}
          {/*  disablePermanent={disablePermanent}*/}
          {/*  onClick={() => setMobileOpen(true)}*/}
          {/*  sx={{ ml: '1px' }}*/}
          {/*>*/}
          {/*  <SvgHamburgerMenu />*/}
          {/*</NavIconButton>*/}

          {/*<Link href="/"*/}
          {/*      // passHref*/}
          {/*      legacybehavior="true"*/}
          {/*>*/}
          {/*  <Box*/}
          {/*    // component="a"*/}
          {/*    // aria-label={t('goToHome')}*/}
          {/*    aria-label='Home'*/}
          {/*    sx={{ display: 'flex', ml: 2, mr: 2  }}*/}
          {/*    // sx={{ display: { md: 'flex', lg: 'none'}, ml: 2 }}*/}
          {/*  >*/}
          {/*    <SvgMuiLogomark width={30} />*/}
          {/*  </Box>*/}
          {/*</Link>*/}

          {/*<GrowingDiv />*/}

          {/*<Stack direction="row" spacing="10px">*/}
          {/*  <>{additionalHeaderButtons}</>*/}
          {/*  {BannerComponent ? BannerComponent : null}*/}
          {/*  /!*<BannerComponent />*!/*/}
          {/*  /!*<DeferredAppSearch />*!/*/}
          {/*  /!*<Tooltip title={'GitHub'} enterDelay={300}>*!/*/}
          {/*  /!*  <IconButton*!/*/}
          {/*  /!*    component="a"*!/*/}
          {/*  /!*    color="primary"*!/*/}
          {/*  /!*    // href={process.env.SOURCE_CODE_REPO}*!/*/}
          {/*  /!*    data-ga-event-category="header"*!/*/}
          {/*  /!*    data-ga-event-action="github"*!/*/}
          {/*  /!*  >*!/*/}
          {/*  /!*    <PlayLesson fontSize="small" />*!/*/}
          {/*  /!*  </IconButton>*!/*/}
          {/*  /!*</Tooltip>*!/*/}

          {/*  <Notifications />*/}
          {/*  <Tooltip title={'Toggle Settings'} enterDelay={300}>*/}
          {/*    <IconButton color="primary" onClick={() => setSettingsOpen(true)} sx={{ px: '8px' }}>*/}
          {/*      <SettingsOutlined fontSize="small" />*/}
          {/*      /!*Settings*!/*/}
          {/*    </IconButton>*/}
          {/*  </Tooltip>*/}
          {/*</Stack>*/}

        </Toolbar>
      </StyledAppBar>


      {/*<StyledAppNavDrawer*/}
      {/*  disablePermanent={disablePermanent}*/}
      {/*  onClose={() => setMobileOpen(false)}*/}
      {/*  onOpen={() => setMobileOpen(true)}*/}
      {/*  mobileOpen={mobileOpen}*/}
      {/*/>*/}
      {children}
      <AppSettingsDrawer onClose={() => setSettingsOpen(false)}
                         open={settingsOpen}>
        {settingsAdditionalChildren}
      </AppSettingsDrawer>
    </RootDiv>
  );
}

//
// AppFrame.propTypes = {
//   BannerComponent: PropTypes.elementType,
//   children: PropTypes.node.isRequired,
//   className: PropTypes.string,
//   disableDrawer: PropTypes.bool,
// };
