import {create} from 'zustand'
import {Chat} from "../models/Chat";
import {RequestMsg} from "../models/RequestMsg";
import {AppSoundMode} from "./appSoundMode";
import {CheckPronunciationResponse} from "../models/CheckPronunciationResponse";
import update from "immutability-helper";
import {BackendRestApiInstance} from "../api/BackendRestApi";

import {persist} from "zustand/middleware";
import {Profile} from "../models/Profile";

// HACK: Posthog strongly recommends to use useHook, however, it is
// ref: https://posthog.com/docs/libraries/react#using-posthog-js-functions
import posthog from 'posthog-js'
// import { devtools, persist } from 'zustand/middleware'
import _ from 'lodash';

function deepClone<T>(obj: T): T {
  /***
   * Type-preserving deep clone
   */
  return _.cloneDeep(obj);
}

function toSnakeCaseShallow(original) {
  /**
   * IMPORTANT: converts only root-level properties
   */
    // TODO: use lodash
  let newObject = {};

  function camelToUnderscore(key) {
    return key.replace(/([A-Z])/g, "_$1").toLowerCase();
  }

  for (let camel in original) {
    newObject[camelToUnderscore(camel)] = original[camel];
  }

  return newObject
}


//
// above is the same as:
// type AppSoundModeStrings = "Default" | "pronunciation"

interface AppGlobalState {
  loginToken: string | null
  setLoginToken: (loginToken: string | null) => void

  apiUserInfo: Profile | null
  setApiUserInfo: (apiUserInfo: Profile | null) => void
  firebaseApiUser: any
  setFirebaseApiUser: (firebaseApiUserInfo: object | null) => void

  showDashboardWindow: boolean
  setShowDashboardWindow: (showDashboardWindow: boolean) => void

  chatMessagesBeingLoaded: boolean
  setChatMessagesBeingLoaded: (chatMessagesBeingLoaded: boolean) => void

  // showDebug: boolean
  // setShowDebug: (showDebug: boolean) => void
  chats: Chat[]
  setChats: (chats: Chat[]) => void
  /*
  added prop:
  isProcessingUserMessage
   */
  currentChat: Chat
  setCurrentChat: (chat: Chat, messages: Record<number, RequestMsg>, fetchMessages: boolean) => void

  getMessageIsPlaying: (requestTs: number)=> boolean

  // currentChatMessagesSentencePlaying: (requestIdx, sentenceIdx) => boolean
  // setCurrentChatMessagesSentencePlaying: (requestIdx, sentenceIdx, isPlaying) => void
  chatMessagesIsPlaying: (RequestMsg) => boolean
  setChatMessagesPlaying: (RequestMsg, isPlaying) => void

  setIsPlayingMessage: (RequestMsg, boolean) => void
  playNextMessageIf: (RequestMsg) => void

  currentChatMessages: Record<number, RequestMsg>
  setCurrentChatMessages: (messages: Record<number, RequestMsg>) => void

  currentChatMessagesNotLoaded: number
  setCurrentChatMessagesNotLoaded: (currentChatMessagesNotLoaded: number) => void


  stopChatPlayback: () => void

  // base64-encoded
  currentPronunciationCheckInputAudio: string
  setCurrentPronunciationCheckInputAudio: (val: string) => void

  currentPronunciationCheckResults: CheckPronunciationResponse
  setCurrentPronunciationCheckResults: (val: CheckPronunciationResponse) => void

  currentChatType: string
  setCurrentChatType: (currentChatType: string) => void

  // vad: any
  // setVad: (val: any) => void

  appSoundMode: AppSoundMode
  setAppSoundMode: (val: AppSoundMode) => void

  inputForbiddenSignal: boolean
  setInputForbiddenSignal: (val: boolean) => void

  isConnected: boolean
  setIsConnected: (val: boolean) => void

  isAuthFailed: boolean
  setIsAuthFailed: (val: boolean) => void

  paymentDialogOpen: boolean
  setPaymentDialogOpen: (val: boolean) => void

  accountDeletionDialogOpen: boolean
  setAccountDeletionDialogOpen: (val: boolean) => void

  needMicPermissions: boolean
  setNeedMicPermissions: (val: boolean) => void

  haveMicPermissions: boolean
  setHaveMicPermissions: (val: boolean) => void

  // currentChatMessages: RequestMsg[]
}

/*
see more: https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md


class ChatSettingsBase(BaseModel):
    learnt_language: str
    native_language: str
    language_level: str
    voice_id: str

    id: str = Field(default_factory=lambda: uuid4().hex)  # .hex
    created_at: Optional[datetime] = None

    # note: we do not allow the client to set in explicitly. backend sets it depending on an API called
    chat_type: str = 'default'

    // new: messages - to store chat messages, to display when switching between chats
 */
const useAppStore = create<AppGlobalState>()((set, get) => ({
  // const posthog = usePostHog(),

  loginToken: process.env.REACT_APP_DEV_INIT_LOGIN_TOKEN,
  setLoginToken: (loginToken) => set(() => ({loginToken: loginToken})),

  apiUserInfo: null,
  setApiUserInfo: (apiUserInfo) => set(() => {
    const user = get().apiUserInfo;
    if (user !== apiUserInfo) {
      if (!apiUserInfo) {
        // nullify identity
        // console.log('nullify identify', apiUserInfo)
        posthog?.reset()
      } else {
        // console.log('identify', apiUserInfo)
        posthog?.identify(apiUserInfo.userId, toSnakeCaseShallow(apiUserInfo))
      }
    }

    return {apiUserInfo: apiUserInfo}
  }),

  firebaseApiUser: null,
  setFirebaseApiUser: (firebaseApiUser) => set(() => ({firebaseApiUser: firebaseApiUser})),

  showDashboardWindow: false,
  setShowDashboardWindow: (showDashboardWindow: boolean) => set(() => ({showDashboardWindow: showDashboardWindow})),

  chatMessagesBeingLoaded: false,
  setChatMessagesBeingLoaded: (chatMessagesBeingLoaded: boolean) => set(() => ({chatMessagesBeingLoaded: chatMessagesBeingLoaded})),

  // showDebug: false,
  // setShowDebug: (showDebug) => set(() => ({showDebug: showDebug})),
  chats: [],
  setChats: (chats: Chat[]) => set(() => ({chats: chats})),

  currentChat: null,
  setCurrentChat: (chat: Chat, messages: Record<number, RequestMsg>, fetchMessages: boolean = false) => set(() => {
      if (!messages) {
        // messages cannot be null
        messages = {}
      }

      if (fetchMessages) {
        BackendRestApiInstance.getChatMessages(chat.id);
      }

      return {currentChat: chat, currentChatMessages: messages}
    }
  ),

  currentChatMessages: {},
  setCurrentChatMessages: (messages: Record<number, RequestMsg>) => set(() => {
    if (!messages) {
      // messages cannot be null
      messages = {}
    }

    return {currentChatMessages: messages}
  }),

  currentChatMessagesNotLoaded: 0,
  setCurrentChatMessagesNotLoaded: (val: number) => set(() => ({currentChatMessagesNotLoaded: val})),


  stopChatPlayback: () => set((state: AppGlobalState) => {
    let messagesCurrent:Record<number, RequestMsg> = state.currentChatMessages;
    // console.log('updateSentenceState messagesCurrent', messagesCurrent)

    if (!messagesCurrent) {
      // console.log('updateSentenceState !state.currentChatMessages')

      return {currentChatMessages: messagesCurrent};
    }

    // stop
    for (const [key, req] of Object.entries(messagesCurrent)) {
      req.playing=false
    }

    // update the store
    const newData = update(messagesCurrent, {$set: messagesCurrent})

    // const sentenceSpec = {playing: {$set: isPlaying}}
    // const newData = update(messagesCurrent, {
    //   [requestTs]: {
    //     responseSentences: {
    //       [sentenceIdx]: sentenceSpec
    //     }
    //   }
    // });

    return {currentChatMessages: newData};
  }),

  // setChatMessagesPlaying: (requestMsg: RequestMsg, sentenceIdx) => get().currentChatMessages[requestMsg.ts.getUTCMilliseconds()].playing,

  chatMessagesIsPlaying: (requestMsg: RequestMsg)  => get().currentChatMessages[requestMsg.ts.getTime()].playing,

  setChatMessagesPlaying: (requestMsg: RequestMsg, isPlaying) => set((state: AppGlobalState) => {
    let messagesCurrent:Record<number, RequestMsg> = state.currentChatMessages;
    // console.log('setChatMessagesPlaying messagesCurrent', messagesCurrent)

    if (!messagesCurrent) {
      // console.log('setChatMessagesPlaying !state.currentChatMessages')

      return {currentChatMessages: messagesCurrent};
    }

    // console.log('setChatMessagesPlaying, requestMsg, isPlaying', requestMsg, isPlaying)

    const id = requestMsg.ts.getTime();

    // if (!messagesCurrent.has(id)) {
    if (!(id in messagesCurrent)) {
      // console.warn('Message is not present - cannot stop playing. Cleared the messages too soon?')

      return {currentChatMessages: messagesCurrent};
    }

    const newData = update(messagesCurrent, {
      [id]: {playing: {$set: isPlaying}}
    });

    // console.log('Updating playing to ', requestTs, sentenceIdx, isPlaying, newData)
    return {currentChatMessages: newData};
  }),


  getMessageIsPlaying: (requestTs) => get().currentChatMessages[requestTs].playing,

  // currentChatMessagesSentencePlaying: (requestTs, sentenceIdx) => get().currentChatMessages[requestTs].responseSentences[sentenceIdx].playing,
  // setCurrentChatMessagesSentencePlaying: (requestTs, sentenceIdx, isPlaying) => set((state: AppGlobalState) => {
  //   let messagesCurrent:Record<number, RequestMsg> = state.currentChatMessages;
  //   console.log('updateSentenceState messagesCurrent', messagesCurrent)
  //   console.log('updateSentenceState state', state)
  //
  //   if (!messagesCurrent) {
  //     console.log('updateSentenceState !state.currentChatMessages')
  //
  //     return {currentChatMessages: messagesCurrent};
  //   }
  //
  //   if (!(requestTs in messagesCurrent) || !(sentenceIdx in messagesCurrent[requestTs].responseSentences)) {
  //     console.log('updateSentenceState (!(requestIdx in state.currentChatMessages) || !(sentenceIdx in state.currentChatMessages[requestIdx]))', requestTs, sentenceIdx)
  //
  //     return {currentChatMessages: messagesCurrent};
  //   }
  //
  //   const sentenceSpec = {playing: {$set: isPlaying}}
  //   const newData = update(messagesCurrent, {
  //     [requestTs]: {
  //       responseSentences: {
  //         [sentenceIdx]: sentenceSpec
  //       }
  //     }
  //   });
  //
  //   console.log('Updating playing to ', requestTs, sentenceIdx, isPlaying, newData)
  //   return {currentChatMessages: newData};
  // }),

  setIsPlayingMessage: (msg: RequestMsg, isPlaying: boolean) => set((state: AppGlobalState) => {
    let messagesCurrent:Record<number, RequestMsg> = state.currentChatMessages;
    // console.log('setIsPlayingMessage messagesCurrent', messagesCurrent)

    if (!messagesCurrent) {
      // console.log('setIsPlayingMessage !state.currentChatMessages')

      return {currentChatMessages: messagesCurrent};
    }

    const sentenceSpec = {playing: {$set: isPlaying}}
    const newData = update(messagesCurrent, {
      [msg.ts.getTime()]: sentenceSpec
    });

    // console.log('Updating playing to ', requestTs, sentenceIdx, isPlaying, newData)
    return {currentChatMessages: newData};
  }),

  playNextMessageIf: (msg: RequestMsg) => set((state: AppGlobalState) => {
    let messagesCurrent:Record<number, RequestMsg> = state.currentChatMessages;
    // console.log('playNextMessageIf messagesCurrent', messagesCurrent)

    if (!messagesCurrent) {
      // console.log('playNextMessageIf !state.currentChatMessages')

      return {currentChatMessages: messagesCurrent};
    }

    const sortedKeys = Array.from( Object.keys(messagesCurrent)).sort(function(a, b) {
      return Number(a) - Number(b);
    });

    let newData = update(messagesCurrent, {
      [msg.ts.getTime()]: {playing: {$set: false}}
    });

    // FIXME: costly conversions
    const idx = sortedKeys.findIndex((element) => Number(element) === msg.ts.getTime())
    if (idx + 1 < sortedKeys.length) {
      // turn next message on
      const nextTs: number = Number(sortedKeys[idx + 1]);

      // console.log('playNextMessageIf nextTs', nextTs)

      if (msg.replyToTsId && newData[nextTs].replyToTsId && newData[nextTs].replyToTsId.getTime() === msg.replyToTsId.getTime()) {
        // console.log('playNextMessageIf next sentence', newData[nextTs])

        // we play sequentially only sentences within the same response
        newData = update(newData, {
          [nextTs]: {playing: {$set: true}}
        });
      } else {
        // console.warn('playNextMessageIf next sentence is a reply to a different message. stopping')
      }
    } else {
      // console.log('playNextMessageIf no next sentences available. stopping')
    }

    // console.log('Updating playing to ', requestTs, sentenceIdx, isPlaying, newData)
    return {currentChatMessages: newData};
  }),

  // findBlockById: blockId => get().blocksForCurrentPage.find(block => block.blockId === blockId),

  // currentChatMessagesSentencePlaying: (requestIdx, sentenceIdx) =>{
  //   currentChatMessages[requestIdx].responseSentences[sentenceIdx].playing},

  currentPronunciationCheckInputAudio: null,
  setCurrentPronunciationCheckInputAudio: (val: string) => set(() => ({currentPronunciationCheckInputAudio: val})),

  currentPronunciationCheckResults: null,
  setCurrentPronunciationCheckResults: (val: CheckPronunciationResponse) => set(() => ({currentPronunciationCheckResults: val})),

  currentChatType: 'default',
  setCurrentChatType: (chatType: string) => set(() => ({currentChatType: chatType})),

  appSoundMode: AppSoundMode.chat,
  setAppSoundMode: (val: AppSoundMode) => set(() => ({appSoundMode: val})),

  // vad: null,
  // setVad: (val) => set(() => ({vad: val})),

  inputForbiddenSignal: false,
  setInputForbiddenSignal: (val) => set(() => {
    // const f = setInputForbiddenSignal
    // var self = this;

    if (val === true) {
      setTimeout(() => {
        // setInputForbiddenSignal(false)
        // useAppStore.getState().setInputForbiddenSignal(false)
        get().setInputForbiddenSignal(false)
      }, 5000)
    }

    return {inputForbiddenSignal: val}
  }),

  isConnected: false,
  setIsConnected: (val) => set(() => ({isConnected: val})),

  isAuthFailed: true,
  setIsAuthFailed: (val) => set(() => ({isAuthFailed: val})),

  paymentDialogOpen: false,
  setPaymentDialogOpen: (val: boolean) => set(() => ({paymentDialogOpen: val})),

  accountDeletionDialogOpen: false,
  setAccountDeletionDialogOpen: (val: boolean) => set(() => ({accountDeletionDialogOpen: val})),

  needMicPermissions: false,
  setNeedMicPermissions: (val: boolean) => set(() => ({needMicPermissions: val})),

  haveMicPermissions: false,
  setHaveMicPermissions: (val: boolean) => set(() => ({haveMicPermissions: val})),
}))


type SettingsStore = {
  displayResponseText: boolean;
  displayResponseTranslation: boolean;
  fetchLastChatOnPageRefresh: boolean;
  fetchLastChatOnChatDeletion: boolean;

  setDisplayResponseText: (val: boolean) => void;
  setDisplayResponseTranslation: (val: boolean) => void;
}


const useSettingsStore = create<SettingsStore>(
  // @ts-ignore
  persist(
    (set) => ({
      displayResponseText: true,
      displayResponseTranslation: true,
      fetchLastChatOnPageRefresh: true,
      fetchLastChatOnChatDeletion: true,
      // authenticate: async (username, password) => {
      //   set({authenticated: true})
      // },

      setDisplayResponseText: (val: boolean) => set(() => ({displayResponseText: val})),
      setDisplayResponseTranslation: (val: boolean) => set(() => ({displayResponseTranslation: val})),
    }),
    {name: 'settings-store'}
  )
);

export {useAppStore, useSettingsStore};
