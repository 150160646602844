import {useMicVAD} from "./ReactVAD";
import {FRAME_SAMPLES, FRAME_TIME_SEC, MIN_PAUSE_SEC, prepareWav} from "../audioLogicHandler";
import {showPopupInfoMessage} from "./PopupNotifications";
import {useAppStore} from "../state_store";
import {AppSoundMode} from "../state_store/appSoundMode";
import {checkIsBusyAndSignalizeIf, sendChatMessage} from "../LogicHandlerFunctions";
import {play_message_accepted_for_processing_notification} from "../AudioNotifications";
import {useEffect, useState} from "react";

export function useVadManager() {
  const startOnLoad = false
  const streamCancellationExperimental = false

  const {
    setCurrentPronunciationCheckInputAudio,
    setNeedMicPermissions,
    needMicPermissions,
    haveMicPermissions
  } = useAppStore()

  const [stream, setStream] = useState<MediaStream | void>(undefined);

  const [waitingForPermissionsToStart, setWaitingForPermissionsToStart] = useState(false)

  function onVadSpeechEnd(audio: Float32Array) {
    // if (isProcessingUserMessage || !isConnected) {
    switch (useAppStore.getState().appSoundMode) {
      case AppSoundMode.chat:
        if (useAppStore.getState().currentChat) {
          if (!checkIsBusyAndSignalizeIf()) {
            const urlEncodedWav = prepareWav(audio);
            sendChatMessage(urlEncodedWav);
          }
        }
        break
      case AppSoundMode.pronunciation:
        const urlEncodedWav = prepareWav(audio);
        // FIXME: ideally, this should be communication via message bus with the pronunciation training module as a consumer,
        // they should hold the data context as well, rather than the global blackboard
        // BackendRestApiInstance.pronunciationCheck()
        // console.log('onVadSpeechEnd calling setCurrentPronunciationCheckInputAudio')

        // HACK: without resetting ot null, the open modal of pronunciation training does not update the audio
        setCurrentPronunciationCheckInputAudio(null)
        setCurrentPronunciationCheckInputAudio(urlEncodedWav)

        // sent
        play_message_accepted_for_processing_notification();
        break
      default:
        throw new Error(`${useAppStore.getState().appSoundMode} not supported`)
    }
  }

  /*
    from docs, sample rate = 16000
    1(sec)/16000 * frameSamples * redemptionFrames == min_pause_sec
    1/16000 * 1536 * 8 == 0.768 sec = min_pause_sec
    positiveSpeechThreshold: 0.5,
    negativeSpeechThreshold: 0.5 - 0.15,
    preSpeechPadFrames: 1,
    redemptionFrames: 8,
    frameSamples: 1536,
    minSpeechFrames: 3
 */
  // const myvad = await vad.MicVAD.new({


  // setNeedMicPermissions(startOnLoad)

  useEffect(() => {
    // start moved to effect - triggered by 'needMicPermissions' + 'haveMicPermissions'
    console.info('VAD, prereq updated. needMicPermissions: ', needMicPermissions);
    console.info('VAD, prereq updated. haveMicPermissions: ', haveMicPermissions);

    if (needMicPermissions && haveMicPermissions) {
      if (streamCancellationExperimental) {
        (async () => {
          setStream(await createStream());
        })();
      }

      vad.start()
      if (vad.errored) {
        // setWaitingForPermissionsToStart(true)
        console.error('VAD errored 3', vad.errored);
      }
    }

  }, [needMicPermissions, haveMicPermissions]);

  useEffect(() => {
    setNeedMicPermissions(startOnLoad);

    if (startOnLoad) {
      // (async () => {
      //   setStream(await createStream());
      // })();
    }

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);


  function clearStream(
    stream: MediaStream,
  ) {
    if (!stream) {
      console.warn('No stream present')
      return;
    }

    // @ts-ignore
    if (stream.stop) {
      // @ts-ignore
      stream.stop();
    }

    const tracks = stream.getTracks();
    tracks.forEach(track => {
      console.info('Stopping track', track);
      track.stop();
      stream.removeTrack(track);
    });
  }

  async function createStream() {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        // ...fullOptions.additionalAudioConstraints,
        channelCount: 1,
        echoCancellation: true,
        autoGainControl: true,
        noiseSuppression: true,
      },
    })

    return stream
  }


  const vad = useMicVAD({
    // setVad(useMicVAD({

    //   ortConfig: (ort) => {
    //     ort.env.wasm.wasmPaths = {
    //       "ort-wasm-simd.wasm": "/subpath/ort-wasm-simd.wasm",
    //     }
    //   },

    // @ts-ignore
    stream: stream, // <- inject here
    startOnLoad: startOnLoad,
    // speech probability threshold
    userSpeakingThreshold: 0.7, //0.6,
    // sampleRate: SAMPLE_RATE,
    // additionalAudioConstraints: {sampleRate: SAMPLE_RATE},
    frameSamples: FRAME_SAMPLES,
    redemptionFrames: MIN_PAUSE_SEC / FRAME_TIME_SEC,

    // onFrameProcessed: (probabilities) => {
    // },
    // onVADMisfire: () => {
    //   log.debug("VAD misfire");
    // },
    // onSpeechStart: () => {
    //   log.debug("Detected speech start");
    // },

    errored: (message: string) => {
      console.error(message)
    },

    onSpeechEnd: onVadSpeechEnd
  });
// )

  if (startOnLoad) {
    console.log('VAD start, since startOnLoad');
    vad.start();
  }

  if (vad.loading) {
    console.log('VAD loading');
  }

  // actually, this should be 'else if'
  if (vad.errored) {
    // reproduce this by opening http://192.168.0.190:8000/ instead of 127.0.0.1 - when developing locally
    console.error('VAD errored', vad.errored);
    showPopupInfoMessage('Failed to initialize voice engine. Please check site security settings in your browser and enable microphone permissions. Details: ' + vad.errored.message, 'error');
  }

  const pause = () => {
    vad.pause()

    if (streamCancellationExperimental) {
      // @ts-ignore
      clearStream(stream)
      // @ts-ignore
      clearStream(vad.stream)
    }

    // vad.stop()
    // or 'setWantMicStream'
    setNeedMicPermissions(false)
  }

  const toggle = () => {
    if (vad.listening) {
      pause();
    } else {
      console.info('VAD requesting mic permissions', vad.errored);

      // or 'setWantMicStream'
      setNeedMicPermissions(true)

      if (haveMicPermissions) {
        if (streamCancellationExperimental) {
          (async () => {
            setStream(await createStream());
          })();
        }
        vad.start();

        if (vad.errored) {
          // reproduce this by opening http://192.168.0.190:8000/ instead of 127.0.0.1 - when developing locally
          console.error('VAD errored, 2', vad.errored);
        }
      }

      // start moved to effect - triggered by 'needMicPermissions' + 'haveMicPermissions'
      // vad.start()
      // if (vad.errored) {
      //   // setWaitingForPermissionsToStart(true)
      // }

    }
  }

  const start = () => {
    // if (!loading && !errored) {
    //   vad?.start()
    //   setListening(true)
    // }
  }

  // return vad
  return {
    listening: vad.listening,
    userSpeaking: vad.userSpeaking,
    toggle,
    pause
  }
}
