export function createSilentAudio(timeSec, channels, freq = 16000):Float32Array {
  // @ts-ignore
  const AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext;
  if (!AudioContext) {
    console.log("No Audio Context")
  }
  const context = new AudioContext();
  const length = timeSec * freq;
  const audioFile = context.createBuffer(channels, length, freq);
  const array: Float32Array = audioFile.getChannelData(0);
  // return URL.createObjectURL(bufferToWave(audioFile, length));

  return array
}
