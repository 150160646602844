import {useAppStore} from "../state_store";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link} from "@mui/material";
import React from "react";

export default function TopUpBalanceDialog() {
  const {
    paymentDialogOpen,
    setPaymentDialogOpen,
    apiUserInfo
  } = useAppStore()

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setPaymentDialogOpen(true);
  };

  const handleClose = () => {
    setPaymentDialogOpen(false);
  };

  // let badgeColor:"error" | "default" | "success" | "warning" | "info" | "primary" | "secondary"  = apiUserInfo.creditsCents <= 0 ? 'error' : 'success';

  return (

    <Dialog
      fullScreen={fullScreen}
      open={paymentDialogOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Add funds"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          At the moment, you cannot add funds to your accounts yourself. Please contact us to know if you are qualified for a free packet.

          <Link color="inherit"
                target="_blank"
                data-attr="footer:feedback_link"
                href="https://tothecut.youtrack.cloud/form/fb2603d4-9b3e-4e99-a775-c328e5dc2249">
            Request a top up here
          </Link>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
        {/*<Button onClick={handleClose} autoFocus>*/}
        {/*  Agree*/}
        {/*</Button>*/}
      </DialogActions>
    </Dialog>

  );
}
