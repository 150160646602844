let baseAddress = process.env.REACT_APP_BACKEND_HOST;
let isSSL = process.env.REACT_APP_BACKEND_IS_SSL !== '0' && process.env.REACT_APP_BACKEND_IS_SSL !== 'false' && process.env.REACT_APP_BACKEND_IS_SSL !== 'False';

console.log('BACKEND_HOST', process.env.REACT_APP_BACKEND_HOST)
console.log('BACKEND_IS_SSL', process.env.REACT_APP_BACKEND_IS_SSL)

export const SOCKET_URL = (isSSL ? 'wss://' : 'ws://') + baseAddress + '/chat';
export const API_SERVER = (isSSL ? 'https://' : 'http://') + baseAddress

// login with pwd. need to /auth/register with the same payload before that
// export const LOGIN_EP = '/auth/login'
export const AUTH_VERIFY_API_URL = `${API_SERVER}/profiles/verify`
export const CHAT_API_URL = `${API_SERVER}/chats`
export const PRONUNCIATION_CHECK_API_URL = `${API_SERVER}/pronunciation/check`
