import {enqueueSnackbar} from "notistack";

export const showPopupInfoMessage = (infoMessage: string, variant: any = 'success', persist = false, preventDuplicate = true) => {
  // let infoMessage = 'infoMessage'
  /*
    <Alert severity="error">This is an error message!</Alert>
    <Alert severity="warning">This is a warning message!</Alert>
    <Alert severity="info">This is an information message!</Alert>
    <Alert severity="success">This is a success message!</Alert>
   */

  // console.debug(this.props)
  let key = enqueueSnackbar(infoMessage, {
    variant: variant,
    style: {whiteSpace: 'pre-line'},
    preventDuplicate: preventDuplicate,
    persist: persist,
  });

  // when we're back online
  // props.closeSnackbar(key);

  console.info('popup shown', variant, infoMessage)
}
