import React, {useRef} from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Dialog,
  Link,
  Typography, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Box from "@mui/material/Box";
import Bowser from 'bowser';
import {MicOff} from '@mui/icons-material';

import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from 'mic-check';
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import CameraBlockedIcon from "../../assets/ic_blocked_camera_dark.svg";
import {requestAudioPermissions} from "mic-check/lib/requestMediaPermissions";
import {useAppStore} from "../../state_store";
import {useTranslation} from "react-i18next";

/*
see: https://github.com/helenamerk/mic-check/blob/main/examples/react-example/src/components/MediaOnboardingDialog/MediaOnboardingDialog.tsx
 */
const browser = Bowser.getParser(window.navigator.userAgent);

enum DialogType {
  explanation = 'explanation',

  systemDenied = 'systemDenied',
  userDenied = 'userDenied',
  trackError = 'trackError',
}

const MediaOnboardingDialog: React.FC = () => {
  const [showDialog, setShowDialog] = React.useState<DialogType | null>(null);

  const [audioAllowed, setAudioAllowed] = React.useState<boolean>(false);
  // const [videoAllowed, setVideoAllowed] = React.useState<boolean>(false);

  const [errorDetails, setErrorDetails] = React.useState<MediaPermissionsError | undefined>();

  const {needMicPermissions, setNeedMicPermissions, haveMicPermissions, setHaveMicPermissions} = useAppStore()

  const {t, i18n} = useTranslation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Create wrapper refs to access values even during setTimeout
  // https://github.com/facebook/react/issues/14010
  const showDialogRef = useRef(showDialog);
  showDialogRef.current = showDialog;
  const audioAllowedRef = useRef(audioAllowed);
  audioAllowedRef.current = audioAllowed;
  // const videoAllowedRef = useRef(videoAllowed);
  // videoAllowedRef.current = videoAllowed;

  React.useEffect(() => {
    checkMediaPermissions();
  }, [needMicPermissions]);

  React.useEffect(() => {
    console.log('needMicPermissions', needMicPermissions)
  }, [needMicPermissions]);

  React.useEffect(() => {
    console.log('audio allowed permission changed: ', audioAllowed);
    if (audioAllowed
      // || videoAllowed
    ) {
      // set the default devices
      // MediaManager.findMediaDevices();
    }
  }, [audioAllowed
    // , videoAllowed
  ]);

  const checkForExplanationDialog = () => {
    if (needMicPermissions &&
      (!audioAllowedRef.current
        // || !videoAllowedRef.current
      ) &&
      showDialogRef.current === null
    )
      setShowDialog(DialogType.explanation);
  };

  const checkMediaPermissions = () => {
    if (!needMicPermissions) {
      return
    }

    // TODO: listen to if there is a change on the audio/video piece?

    // requestMediaPermissions()
    requestAudioPermissions()
      .then(() => {
        setAudioAllowed(true);
        // setVideoAllowed(true);
        setShowDialog(null);
        setHaveMicPermissions(true);
      })
      .catch((error: MediaPermissionsError) => {
        console.log('MediaOnboardingDialog: ', error);
        if (
          error.type ===
          MediaPermissionsErrorType.SystemPermissionDenied
        ) {
          // user denied permission
          setShowDialog(DialogType.systemDenied);
        } else if (
          error.type ===
          MediaPermissionsErrorType.UserPermissionDenied
        ) {
          // browser doesn't have access to devices
          setShowDialog(DialogType.userDenied);
        } else if (
          error.type ===
          MediaPermissionsErrorType.CouldNotStartVideoSource
        ) {
          // most likely when other apps or tabs are using the cam/mic (mostly windows)
          setShowDialog(DialogType.trackError);
        } else {
        }
        setErrorDetails(error);
      });

    setTimeout(() => {
      checkForExplanationDialog();
    }, 500);
  };

  const _renderTryAgain = (text?: string) => {
    return (
      <
        // style={{width: '100%', marginTop: 20}}
      >
        <Button
          variant="contained"
          color="primary"
          style={{float: 'right'}}
          onClick={() => {
            if (browser.getBrowserName() === 'Safari') {
              // If on Safari, rechecking permissions results in glitches so just refresh the page
              window.location.reload();
            } else {
              checkMediaPermissions();
            }
          }}
        >
          {text ? text : 'Retry'}
        </Button>
      </>
    );
  };

  const _renderErrorMessage = () => {
    if (!errorDetails) return null;
    return (
      <Box
        mt={1}
        // style={{marginTop: 10}}
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreRoundedIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="caption"
                        color="error"
                        // style={{color: 'red'}}
            >
              {t('mediaPermissions.errorDetails')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              {errorDetails.name}: {errorDetails.message}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  const _renderExplanationDialog = () => {
    return (
      <>
        <DialogTitle>
          {t('mediaPermissions.allowTheAppToUseYourMicrophone')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              {t('mediaPermissions.inOrderToChatWithATeacherYouMustAllowTheAppToUseYo')}
            </Typography>
          </DialogContentText>
        </DialogContent>
      </>
    );
  };

  const _renderUserDeniedDialog = () => {
    return (
      <>
        <DialogTitle>
          {t('mediaPermissions.microphoneIsBlocked')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              {t('mediaPermissions.appRequiresAccessToYourMicrophone')}
              {browser.getBrowserName() !== 'Safari' && (
                <Typography>
                  Click the microphone blocked icon{' '}
                  <MicOff></MicOff>{' '}
                  {/*<img*/}
                  {/*  alt="camera blocked"*/}
                  {/*  src={*/}
                  {/*    CameraBlockedIcon*/}
                  {/*    // 'https://www.gstatic.com/meet/ic_blocked_camera_dark_f401bc8ec538ede48315b75286c1511b.svg'*/}
                  {/*  }*/}
                  {/*  style={{display: 'inline'}}*/}
                  {/*/>{' '}*/}
                  in your browser's address bar or enable microphone access via the site settings.
                  {/*If you cannot find the settings, reload the page and you will be asked whether to allow microphone*/}
                  {/*access.*/}
                </Typography>
              )}
            </Typography>
            {_renderErrorMessage()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        {_renderTryAgain()}
        </DialogActions>
      </>
    );
  };

  const _renderSystemDeniedDialog = () => {
    const settingsDataByOS = {
      macOS: {
        name: 'System Preferences',
        // link: 'x-apple.systempreferences:com.apple.preference.security?Privacy_Camera',
        link: 'x-apple.systempreferences:com.apple.preference.security?Privacy_Microphone',
      },
    };

    return (
      <div>
        <DialogTitle>
          {t('mediaPermissions.cantUseYourMicrophone')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              Your browser might not have access to your camera or
              microphone. To fix this problem, open{' '}
              {
                // @ts-ignore
                settingsDataByOS[browser.getOSName()] ? (
                  <Link
                    onClick={() => {
                      window.open(
                        // @ts-ignore
                        settingsDataByOS[browser.getOSName()]
                          .link,
                        '_blank',
                      );
                    }}
                  >
                    {
                      // @ts-ignore
                      settingsDataByOS[browser.getOSName()].name
                    }
                  </Link>
                ) : (
                  'Settings'
                )
              }
              .
            </Typography>
            {_renderErrorMessage()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        {_renderTryAgain()}
        </DialogActions>
      </div>
    );
  };

  const _renderTrackErrorDialog = () => {
    return (
      <>
        <DialogTitle>
          {t('mediaPermissions.cantStartYourMicrophone')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              {t('mediaPermissions.anotherApplicationZoomSkypeEtcOrBrowserTabGoogleMe')}
            </Typography>
            {_renderErrorMessage()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        {_renderTryAgain()}
        </DialogActions>
      </>
    );
  };

  const _renderDialogContent = () => {
    switch (showDialog) {
      case DialogType.explanation:
        return _renderExplanationDialog();
      case DialogType.systemDenied:
        return _renderSystemDeniedDialog();
      case DialogType.userDenied:
        return _renderUserDeniedDialog();
      case DialogType.trackError:
        return _renderTrackErrorDialog();
    }
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      open={!!showDialog}>
      {showDialog && _renderDialogContent()}
    </Dialog>
  );
};

export default MediaOnboardingDialog;
