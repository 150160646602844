import { green, red, blue, teal, purple, lime, pink, blueGrey, lightBlue, indigo } from '@mui/material/colors';
import {ThemeProvider, createTheme, PaletteOptions} from "@mui/material/styles";
import { useMemo } from 'react';
import {brandingLightTheme} from "./brandingTheme";
// import {green, red} from "@mui/material/colors";

export const okColor = green[500]
export const notOkColor = red[500]

export const BgDark = '#0d1117'
export const BgLight = '#fff'
export const AppBarDark = '#000' //'#020408'
export const AppBarLight = '#bbb'
export const FooterDark=BgDark
export const FooterLight=BgLight

export const BgDark2 = '#1c2128'

// https://mui.com/material-ui/customization/palette/
// https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette
// https://m2.material.io/design/color/the-color-system.html#color-theme-creation
const mode = 'light'
// export const AppTheme = brandingLightTheme
// export const AppTheme = createTheme({
//   palette: {
//     'light'
//   }
// })

export const AppTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

//
// export const AppTheme = useMemo(
//   () =>
//     createTheme({
//       palette: {
//         mode,
//       },
//     }),
//   [mode],
// );

// export const AppTheme = createTheme({
//     palette: {
//         // mode: "dark",
//         mode: "light",
//         // primary.light
//         background: {
//             default: BgDark,
//             // paper: BgDark,
//         },
//         primary: {
//             // main: '#FF5733',
//             // https://mui.com/material-ui/customization/color/#2014-material-design-color-palettes
//             main: lightBlue[400],
//             // light: will be calculated from palette.primary.main,
//             // dark: will be calculated from palette.primary.main,
//             // contrastText: will be calculated to contrast with palette.primary.main
//         },
//         // secondary: {
//         //     main: '#E0C2FF',
//         //     // light: '#F5EBFF',
//         //     // dark: will be calculated from palette.secondary.main,
//         //     // contrastText: '#47008F',
//         // },
//     },
//
//     typography: {
//         // "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
//         // fontFamily: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
//         fontSize: 12,
//         // "fontWeightLight": 300,
//         // "fontWeightRegular": 400,
//         // "fontWeightMedium": 500
//     }
// });


// IMPORTANT: same as footer color
// theme.palette.background.paper = theme.palette.background.default = theme.palette.mode === "light"
//     ? '#fff' //theme.palette.grey[200]
//     : '#1c2128' //theme.palette.grey[800];

