import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {useAppStore} from "../state_store";
import {Box, Divider} from "@mui/material";
import {SpinningRefreshIcon} from "./SpinningRefreshIcon";
import clsx from "clsx";
import {green} from "@mui/material/colors";
import {LoginOutlined, KeyOff, CloudDoneOutlined,CloudOffOutlined, RecordVoiceOver, DoNotDisturbOnTotalSilence} from "@mui/icons-material";
import {notOkColor, okColor} from "./baseStyles";

const useInputForbiddenStyles = makeStyles((theme) => ({

  '@-o-keyframes fadeIt': {
    // '0%': {color: '#FFFFFF'},
    '30%': {color: '#AD301B'},
    '70%': {color: '#AD301B'},
    // '100%': {color: '#FFFFFF'}
  },
  '@keyframes fadeIt': {
    // '0%': {color: '#FFFFFF'},
    '30%': {color: '#AD301B'},
    '70%': {color: '#AD301B'},
    // '100%': {color: '#FFFFFF'}
  },

  inputForbiddenAnimation: {
    '-o-animation': '$fadeIt 1s 4 ease-in-out',
    animation: '$fadeIt 1s 4 ease-in-out'
  },

}));

export function StatusWidget(props) {
  const {vad, children, ...other} = props;

  const classes = useInputForbiddenStyles();

  const {inputForbiddenSignal, setInputForbiddenSignal} = useAppStore()
  // const [inputForbiddenSignal, setInputForbiddenSignal] = useState(false)

  const {isConnected, setIsConnected} = useAppStore()
  const {isAuthFailed, setIsAuthFailed} = useAppStore()
  // let vadActivityColor = (vad.listening ? okColor : notOkColor)

  // const {vad, setVad} = useAppStore()

  return <Box
    style={{
      width: "fit-content"
    }}
    sx={{
      display: 'flex', flexGrow: 0,
      alignItems: 'center',

      // border: '1px solid',
      // borderColor: 'divider',
      // borderRadius: 2,

      bgcolor: 'background.paper',
      color: 'text.secondary',
      '& svg': {
        m: 1,
      },
    }}
  >
    {/*<RecordVoiceOverIcon/>*/}
    {/*<Divider orientation="vertical" variant="middle" flexItem/>*/}
    {/*<VoiceOverOffIcon/>*/}
    {/*<Divider orientation="vertical" variant="middle" flexItem/>*/}
    {/*<CampaignIcon/>*/}

    {/*{isProcessingUserMessage*/}
    {useAppStore.getState().currentChat && useAppStore.getState().currentChat.isProcessingUserMessage
      ? <><SpinningRefreshIcon/>
        <Divider orientation="vertical" variant="middle" flexItem/>
        <DoNotDisturbOnTotalSilence
          className={clsx({
            [classes.inputForbiddenAnimation]: inputForbiddenSignal,
          })}
        />
        <Divider orientation="vertical" variant="middle" flexItem/>
      </>
      : null}

    <RecordVoiceOver style={{color: (vad && vad.userSpeaking ? green[500] : null)}}
                         fontSize="small"></RecordVoiceOver>
    <Divider orientation="vertical" variant="middle" flexItem/>

    {/*<MicIcon style={{color: vadActivityColor}} fontSize="small"></MicIcon>*/}
    {/*<Divider orientation="vertical" variant="middle" flexItem/>*/}

    {isConnected
      ? <CloudDoneOutlined style={{color: okColor}} fontSize="small"/>
      : <CloudOffOutlined style={{color: notOkColor}} fontSize="small"/>
    }

    {isAuthFailed
      ? <><Divider orientation="vertical" variant="middle" flexItem/><KeyOff style={{color: notOkColor}} fontSize="small"/></>
      : null
    }

  </Box>;
}
