/***
 * See:
 * https://javascript.plainenglish.io/decoupling-react-components-with-an-event-bus-9d86170e09d7
 * https://medium.com/@nouraldin.alsweirki/pub-sub-pattern-in-react-example-c5bbd08fa02f
 * https://jasonwatmore.com/post/2019/02/13/react-rxjs-communicating-between-components-with-observable-subject
 * https://blog.logrocket.com/rxjs-react-hooks-for-state-management/
 */

import { Bus } from '@rxfx/bus';
import { after } from '@rxfx/after';
// @ts-nocheck
import { actionCreatorFactory } from '@rxfx/fsa';
import {Action, createService} from "@rxfx/service";

//////
// import { Omnibus } from "omnibus-rxjs";
// import { Action } from "typescript-fsa";


// export const OMNIBUS = new Omnibus<Action<any>>();
// // OMNIBUS.spy(({ type, payload }) => console.log(type, payload));
// // export const OMNIBUS = new Omnibus<object>();
// OMNIBUS.spy((event) => {
//     console.warn("OMNIBUS Event Bus: ", event);
// });
// OMNIBUS.errors.subscribe((e) => console.error(e));


////////////////////
// export enum EventNamespaces {
//     AUTH = 'AUTH',
//     NOTIFICATION = 'NOTIFICATION',
// }
export enum EventTypes {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    // first of all, for notification stack. consider parameters passed with the message to define notification behaviour
    REQUEST_NOTIFICATION_SHOW = 'NOTIFICATION_SHOW',

    REQUEST_PROGRESS_SHOW = 'progress_show',
    REQUEST_PROGRESS_HIDE = 'progress_hide',

    REQUEST_LOGIN = 'REQUEST_LOGIN',
    REQUEST_GENERATION = 'REQUEST_GENERATION',
    //... more events
    REQUEST_LOAD_TASK = 'REQUEST_LOAD_TASK',
}

export enum EventStatuses {
    REQUEST = 'request',
    CANCEL = 'cancel', //
    STARTED = 'started',
    NEXT = 'next',
    ERROR = 'error', //
    COMPLETE = 'complete', //
    CANCELED = 'canceled', //
    LOADING = 'loading', // additional?
    RESULT = 'result', // additional?

}
/* // from sources of createService
        request: namespacedAction('request'),
        cancel: namespacedAction('cancel'),
        started: namespacedAction('started'),
        next: namespacedAction('next'),
        error: namespacedAction('error'),
        complete: namespacedAction('complete'),
        canceled: namespacedAction('canceled'),
 */
// -
const authLoginMessageCreator = actionCreatorFactory(EventTypes.LOGIN);
const authLogoutMessageCreator = actionCreatorFactory(EventTypes.LOGOUT);
export interface AuthRequestLogin {
    token: string | null
}
export interface AuthRequestLogout {}
export const authLoginMessage = authLoginMessageCreator<AuthRequestLogin>(EventStatuses.REQUEST);
export const authLogoutMessage = authLogoutMessageCreator<AuthRequestLogout>(EventStatuses.REQUEST);
// -
export const notificationRequestMessageCreator = actionCreatorFactory(EventTypes.REQUEST_NOTIFICATION_SHOW);
export interface NotificationRequest {
    notificationType: string, // TODO: enum error, warn, success
    notificationMessage: string
}
export const notificationRequestMessage = notificationRequestMessageCreator<NotificationRequest>(EventStatuses.REQUEST);
//

export const generationRequestMessageCreator = actionCreatorFactory(EventTypes.REQUEST_GENERATION);
export interface GenerationRequest {
    positivePrompt: string,
    negativePrompt: string | null,
    seed: number,
    low_quality: boolean,
    token: string | null,
    server: string | null

    /*
        prompt: str
    seed: int
    negative_prompt: str = None
    low_quality: bool = True
    depth: bool = True
    normals: bool = True
    hdr: bool = True
    quality: str = 'medium'
     */
}
export const generationRequestMessage = generationRequestMessageCreator<GenerationRequest>(EventStatuses.REQUEST);


//

export const loadTaskRequestMessageCreator = actionCreatorFactory(EventTypes.REQUEST_LOAD_TASK);
export interface LoadTaskRequest {
    taskId: string,
    token: string | null
}
export const loadTaskRequestMessage = loadTaskRequestMessageCreator<LoadTaskRequest>(EventStatuses.REQUEST);



//
export const loginRequestMessageCreator = actionCreatorFactory(EventTypes.REQUEST_LOGIN);
export interface LoginRequest {
    message: string
}
export const loginRequestMessage = loginRequestMessageCreator<LoginRequest>(EventStatuses.REQUEST);


export const progressUpdateRequestActionCreator = actionCreatorFactory(EventTypes.REQUEST_PROGRESS_SHOW);
export interface ProgressUpdateRequest {
    rate: number,
    show: boolean,
    indefinite: boolean,
    message: string
}
export const progressUpdateRequestMessage = progressUpdateRequestActionCreator<ProgressUpdateRequest>(EventStatuses.REQUEST);


////

const searchAction = actionCreatorFactory('search');
export interface SearchRequest {
    query: string;
    id?: number;
}
export interface SearchLoading {
    request?: { id: number };
}
export interface SearchComplete {
    request?: { id: number };
    result: [string];
}
export interface SearchError extends Error {
    request?: { id: number };
}
/**  An individual result*/
export interface SearchResult {
    result: string;
    request?: { id: number };
}
export interface SearchCanceled {
    request?: { id: number };
}

//#region "Actions We Listen For"
// Input event:
export const searchRequestCreator = searchAction<SearchRequest>('request');
//#endregion

//#region "Actions We Respond With"
/* Output event: we are loading your search.. */
export const loadingCreator = searchAction<SearchLoading>('loading');
/* Output event containing a single search result */
export const resultCreator = searchAction<SearchResult>('result');
/* Output event indicating your search has errored */
export const errorCreator = searchAction<SearchError>('error');
/* Output event indicating your search is complete */
export const completeCreator = searchAction<SearchComplete>('complete');

export const cancelCreator = searchAction<SearchCanceled>('cancel');


//
export const FSABus = new Bus<Action<any>>();
FSABus.spy(({type, payload}) => {
    console.debug('BUS SPY', type, payload);
});
FSABus.errors.subscribe(console.error);

/*
    interface Foo {
      foo: string;
      value?: string;
    }
    interface Bar extends Foo {
      bar: string;
      value?: string;
    }


   FSABus.trigger({ type: 'foo' });
          FSABus.trigger({ type: 'bar' });
  expect(events).toEqual([{ type: 'foo' }]);

  const asyncCounter = createService(
    Foo,
    FSABus,
    () => after(1000),
    () => reducer
);
 */


/*
////
const eventBus = new Bus<string>(); // or Redux FSA, etc.

/////// Listen and limit events (critera, handler) ////////
const listener = eventBus.listen(
    () => true,
    (event) => after(1000, () => console.log(event))
);
eventBus.guard(e => !e.length, () => {
    throw new Error('non-empty strings!')}
);

/////// Then trigger them /////////////////////////////////
eventBus.trigger('Hello');
eventBus.trigger('World');

/////// Turn off listener (upon unmount, for example) /////
after(2000).then(() => listener.unsubscribe());
*/


/*
// A framework-free, pure-JS reducer
const reducer = (count = 0, e = {} as Action<any>) => {
    if (e.type === "count/next") {
        return count + 1;
    }
    return count;
};

// A service to tie the reducer to an async effect
const asyncCounter = createService(
    "count",
    eventBus,
    () => after(1000),
    () => reducer
);

// A component using the service for state, activity tracking
export const Counter = () => {
    // useWhileMounted(fn) equals useEffect(fn, []);
    useWhileMounted(() => {
        asyncCounter.request();
    });

    const { state: count, isActive } = useService(asyncCounter);
    const buttonLabel = isActive ? "⏳" : "Increment";

    return (
        <div>
            <h1> ⚛️ Count is {count} </h1>
    <button onClick={() => asyncCounter.request()}>
    {buttonLabel}
    </button>
    </div>
);
};
*/

/////

////

// import { useState, useEffect } from 'react';
// import {filter, Subject} from 'rxjs';
// import {Action, createService} from "@rxfx/service";

/*
   // emit events
   eventBus.next({ type: EventTypes.LOGIN, payload: { username: 'johndoe' } });
   ...
   // subscribe to events
   eventBus.subscribe((event) => {
      switch (event.type) {
        case EventTypes.LOGIN:
          console.log(`User ${event.payload.username} has logged in`);
          break;
        case EventTypes.LOGOUT:
          console.log(`User ${event.payload.username} has logged out`);
          break;
        default:
          break;
      }
    });
 */



/*
const eventBus = new Subject();

const useEvents = (predicateFn:any) => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const subscription = eventBus.pipe(filter(predicateFn)).subscribe(setEvents);
        return () => subscription.unsubscribe();
    }, [predicateFn]);

    const publish = (event:any) => eventBus.next(event);

    return { events, publish };
};
*/

/*
import { useEffect } from 'react';
import { EventEmitter } from 'eventemitter3';

const emitter = new EventEmitter();

export const useSub = (event, callback) => {
    const unsubscribe = () => {
        emitter.off(event, callback);
    };

    useEffect(() => {
        emitter.on(event, callback);
        return unsubscribe;
    }, []);

    return unsubscribe;
};

export const usePub = () => {
    return (event, data) => {
        emitter.emit(event, data);
    };
};*/
