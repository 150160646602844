// "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:4000';
// "undefined" means the URL will be computed from the `window.location` object
import {io} from "socket.io-client";
import {SOCKET_URL} from "./ApiEndpoints";
import {useAppStore} from "../state_store";

export const socket = io(SOCKET_URL, {
  auth: {
    token: useAppStore.getState().loginToken,
    // user_name: "[user]"
  },

// /**
//  * The maximum number of retries. Above the limit, the packet will be discarded.
//  *
//  * Using `Infinity` means the delivery guarantee is "at-least-once" (instead of "at-most-once" by default), but a
//  * smaller value like 10 should be sufficient in practice.
//  */
// retries?: number;
// /**
//  * The default timeout in milliseconds used when waiting for an acknowledgement.
//  */
// ackTimeout?: number;
  // connectionStateRecovery: {
  //   // the backup duration of the sessions and the packets
  //   maxDisconnectionDuration: 2 * 60 * 1000,
  //   // whether to skip middlewares upon successful recovery
  //   skipMiddlewares: true,
  // }
  transports: ['websocket'],
  autoConnect: false,
  reconnectionDelay: 1000, // defaults to 1000
  reconnectionDelayMax: 2000, // defaults to 5000
});

// Socket.IO does guarantee message ordering, no matter which low-level transport is used (even during an upgrade from HTTP long-polling to WebSocket).
/*
By default, Socket.IO provides an at most once guarantee of delivery:

if the connection is broken while an event is being sent, then there is no guarantee that the other side has received it and there will be no retry upon reconnection
a disconnected client will buffer events until reconnection (though the previous point still applies)
there is no such buffer on the server, which means that any event that was missed by a disconnected client will not be transmitted to that client upon reconnection

At least once
From client to server
From the client side, you can achieve an at least once guarantee with acknowledgements and timeouts:

From server to client
For events sent by the server, additional delivery guarantees can be implemented by:

assigning a unique ID to each event
persisting the events in a database
storing the offset of the last received event on the client side, and send it upon reconnection
 */

if (process.env.NODE_ENV !== 'production') {
  localStorage.debug = '*';
}

socket.io.on("reconnect_attempt", function () {
  console.log("reconnecting")
});

socket.io.on('reconnect_failed', function () {
  console.log("reconnect failed");
});

export const sendUserMessage = (lessonId, requestIdx, ts , urlEncodedWavRequest, text=null, task=null, respond_with_voice=true) => {
  /*
  By default, any event emitted while the Socket is not connected will be buffered until reconnection.
  While useful in most cases (when the reconnection delay is short), it could result in a huge spike of events when the connection is restored.
  There are several solutions to prevent this behavior, depending on your use case:
   */
  if (socket.connected) {
    console.log('Sending message (socket is connected)')
  } else {
    console.log('Sending message (socket IS NOT connected)')
  }

  // FIXME: lesson id should change when we want to reset the conversation context or change settings: lang, level etc
  // FIXME: use DTO
  let data = {
    'lesson_id': lessonId,
    'request_idx': requestIdx,
    'ts': ts,
    'wav_data': urlEncodedWavRequest,
    'text': text,
    'task': task,
    'respond_with_voice': respond_with_voice
  }
  socket.emit('chat_message', data);
  // socket.timeout(5000).emit('chat_message', data);
}
