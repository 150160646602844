import React from "react";
import {containerStyle} from "./containerStyle";
import IconButton from "@mui/material/IconButton";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {AudioPlayerProps} from "./audioPlayerProps";


interface PlayPauseButtonProps
// TODO: -> extends AudioPlayerProps
  extends Pick<AudioPlayerProps, "display" | "playPauseIconButtonProps"> {
  disabled?: boolean;
  // audioElement: HTMLAudioElement | null;
  playing: boolean;
  handlePlay: any;
  // waveSurferRef: React.MutableRefObject<WaveSurferRef>;
}

export function PlayPauseButton(props: PlayPauseButtonProps) {
  const {
    disabled = false,
    // display,
    // audioElement,
    playing,
    handlePlay,
    // waveSurferRef,
    playPauseIconButtonProps = {},
  } = props;

  // const handlePlay = () => {
  //   if (display === "timeline" && audioElement) {
  //     playOrPauseForTimeline(playing, audioElement);
  //
  //     return null;
  //   }
  //
  //   console.log('handlePlay')
  //   playOrPauseForWaveForm(waveSurferRef);
  // };
  //
  // function playOrPauseForTimeline(
  //   playing: PlayPauseButtonProps["playing"],
  //   audioElement: PlayPauseButtonProps["audioElement"]
  // ) {
  //   playing ? audioElement?.pause() : audioElement?.play();
  // }
  //
  // function playOrPauseForWaveForm(
  //   waveSurferRef: PlayPauseButtonProps["waveSurferRef"]
  // ) {
  //   const currentWaveSurfer = waveSurferRef.current;
  //
  //   if (!currentWaveSurfer) return null;
  //
  //   if (playing) {
  //     console.log('playOrPauseForWaveForm: currentWaveSurfer.pause()')
  //     currentWaveSurfer.pause();
  //     return null;
  //   }
  //
  //   console.log('playOrPauseForWaveForm: currentWaveSurfer.play()')
  //   currentWaveSurfer.play();
  // }

  const {sx: iconButtonSx, ...restIconButtonProps} = playPauseIconButtonProps;
  const mergedSx = {...containerStyle.playButton, ...iconButtonSx};

  return (
    <IconButton
      disabled={disabled}
      color="primary"
      onClick={handlePlay}
      sx={mergedSx}
      {...restIconButtonProps}
    >
      {playing ? <PauseIcon/> : <PlayArrowIcon/>}
    </IconButton>
  );
}
