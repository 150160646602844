export class HttpError extends Error {
  get httpCode(): number {
    return this._httpCode;
  }

  private readonly _httpCode: number;

  // private responseText: string;
  constructor(m: string, httpCode: number) {
    super(m);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, HttpError.prototype);

    this._httpCode = httpCode;
    // this.responseText = responseText;
  }

  // foo() {
  //   return "hello " + this.message;
  // }
}

export function fetchApi(url: string, postData: any | null, msgOnSuccess: string | null, onSuccess: any | null, onError: any | null,
                         method: string = 'POST',
                         additionalHeaders: any | null = null,
                         // onAuthError: any | null = null
) {
  // console.debug(`${method} ${url}`, postData)
  // console.debug(JSON.stringify(postData))
  const data = postData ? JSON.stringify(postData,
    // keep undefined fields, turning them into nulls
    function (k, v) {
      return v === undefined ? null : v;
    }) : null;

  // console.debug(data);

  let params: any = {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    mode: 'cors',
    crossDomain: true,
    body: data
  };

  if (additionalHeaders) {
    // params.headers = Object.assign({}, params.headers, additionalHeaders);
    for (let key in additionalHeaders) {
      params.headers[key] = additionalHeaders[key];
    }
  }

  fetch(url, params).then(function (response) {

    if (response.status >= 400) {
      throw new HttpError('Server is rejecting the call. Please reload the page or try agin later.', response.status)
    }

    // console.debug(response.text());

    return response.json()
  }).then(function (data) {
    // console.debug(data);

    if (onSuccess) {
      onSuccess(data);
    }
  }).catch(function (error) {
    /*Handle error*/
    console.error('API error occurred: ', error);
    // console.error(error instanceof HttpError);
    // console.error(error.httpCode);

    // if (onAuthError && (error instanceof HttpError) && (error.httpCode === 401 || error.httpCode === 403)) {
    //   onAuthError(error.httpCode);
    // } else if (onError) {
    //   onError(error);
    // }

    if (onError) {
      onError(error);
    }
  });
}
