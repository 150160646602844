import {throttle} from "throttle-typescript";
import {SliderProps} from "@mui/material";
import React from "react";

export function changeCurrentTimeForTimeline(
  audioElement: HTMLAudioElement | null
): SliderProps["onChange"] {
  return (e: any, v: any) => {
    if (audioElement && typeof v === "number") {
      const currentPosition = (audioElement.duration / 100) * v;

      if (audioElement.fastSeek instanceof Function) {
        audioElement.fastSeek(currentPosition);
      } else {
        audioElement.currentTime = currentPosition;
      }
    }
  };
}

interface InitializeForTimelineArgs {
  src: string;
  audioElement: HTMLAudioElement | null;
  setAudioElement: React.Dispatch<React.SetStateAction<HTMLAudioElement | null>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentTime: React.Dispatch<React.SetStateAction<number>>;
  setEndTime: React.Dispatch<React.SetStateAction<number>>;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setPosition: React.Dispatch<React.SetStateAction<number>>;
}

export function initializeForTimeline(args: InitializeForTimelineArgs) {
  const {
    src,
    audioElement,
    setAudioElement,
    setLoading,
    setCurrentTime,
    setEndTime,
    setPosition,
    setPlaying,
  } = args;

  audioElement?.pause();

  const audio = new Audio(src);

  const makePlaying = () => setPlaying(true);
  const makeNotPlaying = () => setPlaying(false);

  audio.addEventListener("canplaythrough", () => {
    setLoading(false);
    setEndTime(audio.duration);
  });
  audio.addEventListener("playing", makePlaying);
  audio.addEventListener(
    "timeupdate",
    throttle(() => {
      setCurrentTime(audio.currentTime);
      setPosition((audio.currentTime / audio.duration) * 100);
    }, 100)
  );
  audio.addEventListener("pause", makeNotPlaying);
  audio.addEventListener("ended", makeNotPlaying);
  audio.addEventListener("error", (e) => {
    makeNotPlaying();
    console.error(e);
  });

  setAudioElement(audio);
}
