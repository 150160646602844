import {JsonIgnore, JsonProperty} from "json-object-mapper";

/**
 * usage: let responseMsg: ResponseMsg = ObjectMapper.deserialize(ResponseMsg, data);
 *
 *
 * class Address(BaseModel):
 *     name: Optional[str]
 *     line1: Optional[str]
 *     line2: Optional[str]
 *     city: str
 *     state: str
 *     pin: str
 *
 *
 * class Contact(BaseModel):
 *     phone: Optional[str]
 *     email: Optional[str]
 */
export class Profile {
  /*
  IMPORTANT: need this id only for analytics
   */
  @JsonProperty({type: String, name: "user_id"})
  userId: string;

  @JsonProperty({type: Object, name: "address"})
  address: object;
  @JsonProperty({type: Object, name: "native_language"})
  contact: object;
  @JsonProperty({type: String, name: "image_url"})
  imageUrl: string;
  @JsonProperty({type: String, name: "name"})
  name: string;

  @JsonProperty({type: Number, name: "status"})
  status: number;

  @JsonProperty({type: String, name: "verified_email"})
  verifiedEmail: string;

  @JsonProperty({type: String, name: "verifiedPhone"})
  verifiedPhone: string;

  @JsonProperty({type: Date, name: "created_at"})
  createdAt: Date;

  @JsonProperty({type: Date, name: "last_seen_at"})
  lastSeenAt: Date;

  @JsonProperty({type: Number, name: "credits_cents"})
  creditsCents: Number;
}
