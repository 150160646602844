import {WaveSurferProps} from "wavesurfer-react/dist/containers/WaveSurfer";
import {throttle} from "throttle-typescript";
import {PluginType} from "wavesurfer-react/dist/types";
import {useWavesurfer} from "wavesurfer-react";
import React from "react";

export const plugins: PluginType[] = [];
export type WaveSurferRef = ReturnType<typeof useWavesurfer>;

interface GetInitializeWaveSurferParams {
  src: string;
  waveSurferRef: React.MutableRefObject<WaveSurferRef>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentTime: React.Dispatch<React.SetStateAction<number>>;
  setEndTime: React.Dispatch<React.SetStateAction<number>>;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  // setFinish: React.Dispatch<React.SetStateAction<boolean>>;
  playing: any,
  onNotPlaying: any,
  onPlay: any;
  onFinished: any;
  onPaused: any;
}

export function getInitializeWaveSurfer(
  params: GetInitializeWaveSurferParams
): WaveSurferProps["onMount"] {
  const {
    src,
    waveSurferRef,
    setLoading,
    setCurrentTime,
    setEndTime,
    setProgress,
    setPlaying,
    // setFinish,
    playing,
    onNotPlaying,
    onPlay,
    onFinished,
    onPaused
  } = params;

  return function initializeWaveSurfer(waveSurfer: any) {
    if (!waveSurfer) {
      return;
    }

    waveSurferRef.current = waveSurfer;

    if (src) {
      waveSurfer.load(src);
    }

    if (playing) {
      waveSurfer.play()
      // if (waveSurferRef.current)
      //   waveSurferRef.current.play()
    }

    // console.log('playing state', playing)
    // console.log('waveSurfer isPlaying?', waveSurfer.isPlaying())

    const makePlaying = () => setPlaying(true);
    const makeNotPlaying = () => setPlaying(false);

    waveSurfer.on("loading", (n: number) => {
      setProgress(n);
    });
    waveSurfer.on("ready", () => {
      // console.log('waveSurfer.on READY')

      setLoading(false);
      setEndTime(waveSurfer.getDuration());

      // console.log('(ready) playing state', playing)
      // console.log('(ready) waveSurfer isPlaying?', waveSurfer.isPlaying())

      if (playing) {
        waveSurfer.play()
        // if (waveSurferRef.current)
        //   waveSurferRef.current.play()

        // console.log('(ready, after) playing state', playing)
        // console.log('(ready, after) waveSurfer isPlaying?', waveSurfer.isPlaying())
      }
    });
    waveSurfer.on("play", () => {
      // console.log('waveSurfer.on PLAY')
      // makePlaying();

      if (onPlay) {
        onPlay();
      }
    });
    waveSurfer.on(
      "audioprocess",
      throttle((n: number) => {
        setCurrentTime(n);
      }, 100)
    );
    waveSurfer.on("seek", () => {
      // console.log('waveSurfer.on SEEK')

      setCurrentTime(waveSurfer.getCurrentTime());
    });

    waveSurfer.on("finish", () => {
      // console.log('waveSurfer.on FINISH')
      // makeNotPlaying();
      onNotPlaying();

      if (onFinished) {
        onFinished();
      }
    });
    waveSurfer.on("pause", () => {
      // makeNotPlaying();
      // console.log('waveSurfer.on PAUSE')
      onNotPlaying();

      if (onPaused) {
        onPaused();
      }
    });

    waveSurfer.on("destroy", () => {
      // makeNotPlaying();
      onNotPlaying();
    });
    // ["finish", "destroy", "pause"].forEach((e) =>
    //   waveSurfer.on(e, makeNotPlaying)
    // );
    waveSurfer.on("error", (e: any) => {
      // console.log('waveSurfer.on ERROR')

      // makeNotPlaying();
      onNotPlaying();
      // console.error(e);
    });
  };
}
