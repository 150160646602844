import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {ChatMessages} from "./ChatMessages";

export function ChatMessagesContainer(props) {
  const {
    appBarHeight,
    refBottomnSheet,
    vad,
    messages,
    setMessages
  } = props;

  const [bottomnSheetHeight, setBottomnSheetHeight] = useState(0);

  useEffect(() => {
    if (!refBottomnSheet.current) return;
    const resizeObserver = new ResizeObserver(() => {
      // console.log('size changed', refBottomnSheet.current.offsetHeight)
      // Do what you want to do when the size of the element changes
      setBottomnSheetHeight(refBottomnSheet.current.offsetHeight);
    });
    resizeObserver.observe(refBottomnSheet.current);
    return () => resizeObserver.disconnect(); // clean up

  }, []);

  const occludedHeight = appBarHeight + bottomnSheetHeight + 3;
  return <>
    <Box id='screen1' style={{height: `calc(100vh - ${occludedHeight}px)`}}
         sx={{
           display: 'flex', flexDirection: "column"
         }}>

      <ChatMessages vad={vad}
                    audioList={messages} setAudioList={setMessages}
                    sx={{
                      display: 'flex',
                      // flex: '1 1 auto',
                      flexGrow: 1
                    }}/>

      {/*<Divider/>*/}
      {/*{renderChatActions()}*/}

    </Box>
    {/* EO: screen1 */}
  </>;
}
