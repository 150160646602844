import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {Backdrop, Button, Typography} from "@mui/material";
import {TypeSpecimen} from "@mui/icons-material";
import {Action, createService, isType} from "@rxfx/service";
import {
  EventTypes,
  FSABus,
  LoginRequest,
  loginRequestMessage,
  NotificationRequest,
  notificationRequestMessage, ProgressUpdateRequest,
  progressUpdateRequestMessage
} from "../event_bus";
import {after} from "@rxfx/after";
import {useService, useWhileMounted} from "@rxfx/react";
import {useAppStore} from "../state_store";

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

// Inspired by the former Facebook spinners.
function FacebookCircularProgress(props: CircularProgressProps) {
  return (
    <Box sx={{position: 'relative'}}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        // value={props.value}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export default function CustomizedProgressBars() {
  // const [open, setOpen] = React.useState(false);
  // const [progressText, setProgressText] = React.useState("Computer is thinking...");
  // const [progressPercent, setProgressPercent] = React.useState(100);
  // const [progressState, setProgressState] = React.useState<ProgressUpdateRequest>({
  //     rate: 0,
  //     show: false,
  //     indefinite: false,
  //     message: ''
  // });

  const {
    chatMessagesBeingLoaded,
    setChatMessagesBeingLoaded
  } = useAppStore()

  // useWhileMounted(() => {
  //     return FSABus.listen(//(i: TBusItem) => {return true},
  //         progressUpdateRequestMessage.match,
  //         (action) => {
  //             if (isType(action, progressUpdateRequestMessage)) {
  //                 console.warn('RECEIVED: progress_show event', action, progressState);
  //                 // handleAction(e);
  //                 setProgressState(action.payload);
  //
  //                 console.warn('AFTER: ', progressState);
  //             }
  //         })
  // })

  const handleClose = () => {
    // setOpen(false);

    // if (progressState)
    // {
    //     let copy = (JSON.parse(JSON.stringify(progressState)));
    //
    //     copy.show = false;
    //
    //     //progressBusService.request(progressState);
    //     setProgressState(copy);
    //
    //     //FSABus.trigger(progressUpdateRequestMessage(progressState));
    // }
  };

  // const handleOpen = () => {
  //     setOpen(true);
  // };

  // const loadingPercentage = progressState ? Math.round(progressState.rate * 100) : 0;

  return (
    <div>
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={chatMessagesBeingLoaded}
        onClick={handleClose}
      >
        <Box sx={{flexGrow: 1, m: 3}}>
          <FacebookCircularProgress
            variant='indeterminate'
            // value={loadingPercentage}
          />
          {/*<Typography>{progressState ? progressState.message : ''}</Typography>*/}
          {/*<br/>*/}
          <BorderLinearProgress
            variant='indeterminate'
            // variant="determinate" value={loadingPercentage}
          />
        </Box>
      </Backdrop>
    </div>
  );
}
