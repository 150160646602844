import React from "react";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import {Link, ListItem} from "@mui/material";
import {Feedback} from "@mui/icons-material";
import {useAppStore} from "../../state_store";
import {ChatMessageRequestItem} from "./ChatMessageRequestItem";
import {ChatMessageSentenceItem} from "./ChatMessageSentenceItem";
import {ChatCreationForm} from "../ChatCreationForm";
import {VoiceInputButton} from "../VoiceInputButton";
import CustomizedProgressBars from "../ProgressIndicator";
import {RequestMsg} from "../../models/RequestMsg";


/*
//avatarが左にあるメッセージ（他人）
export const MessageLeft = (props) => {
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const photoURL = props.photoURL ? props.photoURL : "dummy.js";
  const displayName = props.displayName ? props.displayName : "名無しさん";
  const classes = chatMessageStyles();
  return (
    <>
      <div className={classes.messageRow}>
        <Avatar
          alt={displayName}
          className={classes.orange}
          src={photoURL}
        ></Avatar>
        <div>
          <div className={classes.displayName}>{displayName}</div>
          <div className={classes.messageBlue}>
            <div>
              <p className={classes.messageContent}>{message}</p>
            </div>
            <div className={classes.messageTimeStampRight}>{timestamp}</div>
          </div>
        </div>
      </div>
    </>
  );
};
//avatarが右にあるメッセージ（自分）
export const MessageRight = (props) => {
  const classes = chatMessageStyles();
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  return (
    <div className={classes.messageRowRight}>
      <div className={classes.messageOrange}>
        <p className={classes.messageContent}>{message}</p>
        <div className={classes.messageTimeStampRight}>{timestamp}</div>
      </div>
    </div>
  );
};
*/


export function ChatMessages(props) {
  const {vad, children, ...other} = props;

  const {currentChat, currentChatMessages, currentChatMessagesNotLoaded} = useAppStore()

  console.log('Rendering chat')

  function renderMessage(requestIdx: string, requestItem: RequestMsg) {

    return <>

      {requestItem.messageType == 'command' ? null :
        // do not render system messages

          requestItem.replyToTsId ?
            // @ts-ignore
            <ListItem key={requestIdx + '_' + requestItem.ts.getTime()} align="left" sx={{display: "list-item"}}>
              <ChatMessageSentenceItem sentenceItem={requestItem} vad={vad}/>
            </ListItem>
            :
            //FIXME:
            // @ts-ignore
            <ListItem key={requestIdx} align="right" sx={{display: "list-item"}}>
              <ChatMessageRequestItem requestItem={requestItem}/>
            </ListItem>
      }
    </>;
  }

  function renderPlaylist() {
    return <List id="playlist" style={{
      overflowY: 'scroll',
      minHeight: '100%', height: '100%', width: '100%',
      // display: "flex",
      // flexDirection: "column",
      // justifyContent: "center"
    }}
    >
      {currentChatMessagesNotLoaded > 0 ? <Box alignItems="center"
                                               sx={{p: 2}}>
        <Typography>Some of your older chat messages ({currentChatMessagesNotLoaded}) were not loaded, as well as
          responses to them.</Typography>
        <Link
          // display="flex"
          target="_blank"
          color="inherit"
          data-attr="chat:feedback_link"
          href="https://tothecut.youtrack.cloud/form/fb2603d4-9b3e-4e99-a775-c328e5dc2249">
          {/*<Typography variant="body2" color="textSecondary" display="flex">*/}

          {/*Not: flex in the container - to avoid icon and email breaking to different lines*/}
          <Feedback style={{verticalAlign: "middle"}}/>
          Please tell us if you want the full chat history
          {/*</Typography>*/}
        </Link>
      </Box> : null
      }
      {
        Object.entries(currentChatMessages)
          // FIXME
          // @ts-ignore
          .sort(([a], [b]) => a - b)
          .map(([requestIdx, requestItem]) => {

            // console.log(requestIdx, requestItem)

            // FIXME:
            // @ts-ignore
            return (
              <React.Fragment key={requestIdx}>
                {renderMessage(requestIdx, requestItem)}
              </React.Fragment>)
          })

      }
    </List>;
  }

  let renderEmptyPlaylist = <Box alignItems="center"
                                 sx={{p: 2, m: 1}}
                                 style={{
                                   minHeight: '100%', height: '100%', width: '100%',
                                   display: "flex",
                                   flexGrow: 1,
                                   flexDirection: "column",
                                   justifyContent: "center"
                                 }}>
    <Typography>Start speaking or writing. You can set a specific task, like 'topic'. Your message will define the
      respected task. See below. Do not forget to enable voice input.</Typography>
    <VoiceInputButton vad={vad}/>
  </Box>;

  return <Box id="playlist-container"
              style={{
                overflowY: 'scroll',
                minHeight: '100%', height: '100%', width: '100%',
                // display: "flex",
                // flexDirection: "column",
                // justifyContent: "center"
              }}
              sx={{
                display: 'flex',
                flexGrow: 1,
              }}>

    <CustomizedProgressBars/>

    {(!currentChatMessages || (Object.keys(currentChatMessages).length === 0))
      ? (currentChat != null ? renderEmptyPlaylist : <ChatCreationForm/>)
      : renderPlaylist()
    }
  </Box>
}
